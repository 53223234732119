import React, { useState, useEffect, useContext } from "react";
import InputRange, { Range } from "react-input-range";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";
import "react-input-range/lib/css/index.css";

const PriceFilter = () => {
  const { state, dispatch } = useContext(FilterContext) as {
    state: any;
    dispatch: any;
  };

  const [values, setValues] = useState<[number, number]>(state.priceValues);

  useEffect(() => {
    setValues(state.priceValues);
  }, [state.priceValues]);

  const multiSliderValuesChange = (data: Range) => {
    setValues([data.min, data.max]);
  };

  const onValuesChangeFinish = (data: Range) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        priceValues: [data.min, data.max],
      },
    });
  };

  if (state.minPrice === state.maxPrice) {
    return null;
  }

  return (
    <section className="sidebar-box sidebar-Price-section">
      <h6>Price</h6>
      <div className="px-3 py-3">
        <InputRange
          minValue={state.minPrice}
          maxValue={state.maxPrice}
          value={{ min: values[0], max: values[1] }}
          onChange={multiSliderValuesChange as any}
          onChangeComplete={onValuesChangeFinish as any}
          formatLabel={(number) => `₹${number}`}
        />
      </div>
    </section>
  );
};

export default PriceFilter;
