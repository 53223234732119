import {
  AUTHENTICATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
} from "../actionTypes/userActionsType";

// Define types for your state
interface UserState {
  login: boolean;
  loading: boolean;
  userType: string;
  active: number;
  address: string;
  city: string;
  dob: string;
  email: string;
  first_name: string;
  full_name: string;
  gender: string;
  id: number;
  last_name: string;
  mobile: string;
  nickname: string;
  pincode: string;
  profile_pic: string;
  verify_number: string;
  settings: Record<string, any>; // or define specific type for settings if possible
}

// Define action types and their payloads
interface LoginAction {
  type: typeof LOGIN;
  payload: Partial<UserState>;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: Partial<UserState>;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface AuthenticateAction {
  type: typeof AUTHENTICATE;
}

type UserActionTypes =
  | LoginAction
  | UpdateUserAction
  | LogoutAction
  | AuthenticateAction;

// Define initial state
const initialState: UserState = {
  login: false,
  loading: true,
  userType: "user",
  active: 0,
  address: "",
  city: "",
  dob: "0000-00-00",
  email: "",
  first_name: "",
  full_name: "",
  gender: "",
  id: 0,
  last_name: "",
  mobile: "",
  nickname: "",
  pincode: "",
  profile_pic: "",
  verify_number: "",
  settings: {},
};

// Define reducer function
const userReducer = (
  state: UserState = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: true,
        loading: false,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
        login: false,
        loading: false,
      };
    case AUTHENTICATE:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
