import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import FacebookLogin, { ReactFacebookLoginInfo } from "react-facebook-login";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";

interface LoginWithSocialProps {
  login: (usertoken: string) => void;
  ToggleModal: () => void;
  ToggleVerifyModal: () => void;
}

const LoginWithSocial: React.FC<LoginWithSocialProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const [validationFormErrors, setValidationFormErrors] = useState<
    Record<string, string>
  >({});
  const [mobile, setMobile] = useState<string>("");

  const signInWithGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ("profileObj" in response) {
      LoginWithGoogle(response.profileObj);
    }
  };

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    LoginWithGoogle(response);
  };

  const LoginWithGoogle = async (userInfo: any) => {
    try {
      const user = userInfo;
      const formData = new FormData();
      formData.append("email", user.email);
      formData.append("name", user.name);
      formData.append("id", user.id);
      formData.append("userData", JSON.stringify(userInfo));
      const res = await Api.post(APIURLS.LOGIN_WITH_GOOGLE, formData);
      const data = res.data;
      if (data.status === "success") {
        const userData = data.user;
        if (userData.mobile === "" || userData.verify_number === 0) {
          setLoading(false);
          setShow(true);
          setUserId(userData.id);
        } else {
          const token_id = data.token_id;
          const token = data.token;
          const usertoken = `${token_id}:${token}`;
          props.login(usertoken);
          props.ToggleModal();
        }
      } else {
        if (data.status_code === 15) {
          const userData = data.user;
          setLoading(false);
          setShow(true);
          setUserId(userData.id);
        } else {
          setLoading(false);
          alert(data.msg);
        }
      }
    } catch (error: any) {
      alert(error.toString());
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);
      formData.append("id", userId.toString());
      const res = await Api.post(APIURLS.ADD_MOBILE, formData);
      const data = res.data;
      if (data.status === "success") {
        localStorage.setItem("usermobile", mobile);
        props.ToggleModal();
        props.ToggleVerifyModal();
      } else if (res.data["title"] === "form Error") {
        setValidationFormErrors(res.data["formErrors"]);
      } else {
        setFormErrors(res.data["msg"]);
      }
    } catch (error) {
      global.alert("Something Wrong Try after sometime.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header
          style={{ backgroundColor: "#1e5698", color: "white" }}
          closeButton
        >
          <Modal.Title>Add Mobile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess !== "" && <Alert variant="success">{formSuccess}</Alert>}
          {formErrors !== "" && <Alert variant="danger">{formErrors}</Alert>}

          <div className="p-3">
            <div className="form-group">
              <label>Mobile</label>
              <input
                className="form-control"
                type="text"
                name="mobile"
                placeholder="Enter mobile number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <div className="invalid-feedback d-block">
                {validationFormErrors.mobile}
              </div>
            </div>
            <div className="form-group submit-btn">
              <input onClick={handleSubmit} type="button" value="SUBMIT" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="form-group login-with">
        <span>Login With</span>
      </div>
      <div className="form-group login-accounts text-center">
        <GoogleLogin
          clientId="989189114788-p1dnh7tet4e89h2o39cepun2iqntdsr3.apps.googleusercontent.com"
          scope={"https://www.googleapis.com/auth/user.birthday.read"}
          fetchBasicProfile={true}
          render={(renderProps) => (
            <img
              onClick={renderProps.onClick}
              src={`${process.env.PUBLIC_URL}/assets/images/google-with.svg`}
              alt="Google login"
            />
          )}
          onSuccess={signInWithGoogle}
          onFailure={(error) => {
            console.log("Login With Google Failed", error);
          }}
        />

        <FacebookLogin
          appId="230207908781822"
          callback={responseFacebook}
          scope="public_profile, email"
          fields="name,email"
          disableMobileRedirect={true}
          redirectUri="https://web.dev.silver-stays.init.mshosting.in/"
          buttonStyle={{
            border: "none",
            backgroundColor: "transparent",
            padding: 0,
          }}
          textButton=""
          icon={
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/fb-with.svg`}
              alt="Facebook login"
            />
          }
        />
      </div>
    </>
  );
};

export default LoginWithSocial;
