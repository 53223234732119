import React from "react";
import Carousel from "react-multi-carousel";
import "./HorizontalRoomViewSlider.css";
import { CONSTANTS } from "../../utils/Constants";

// Define types for the images and hotelImages props
interface Image {
  room_image: string;
  tag: string;
}

interface HotelImage {
  hotel_image: string;
  hotel_image_tag: string;
}

interface HorizontalRoomViewSliderProps {
  images: string; // JSON string of room images
  hotelImages: string; // JSON string of hotel images
}

// Define the type for the images used in the Carousel
interface CarouselImage {
  src: string;
  title: string;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const HorizontalRoomViewSlider: React.FC<HorizontalRoomViewSliderProps> = (
  props
) => {
  const imagesRaw: Image[] = JSON.parse(props.images);
  const hotelRaw: HotelImage[] = JSON.parse(props.hotelImages);
  const images: CarouselImage[] = [];

  // Add Hotel Images
  hotelRaw.forEach((image) => {
    images.push({
      src: `${CONSTANTS.IMAGE_BASE_URL}/hotels/${image.hotel_image}`,
      title: `${image.hotel_image_tag}`,
    });
  });

  // Add Room Images
  imagesRaw.forEach((image) => {
    images.push({
      src: `${CONSTANTS.IMAGE_BASE_URL}/rooms/${image.room_image}`,
      title: `${image.tag}`,
    });
  });

  return (
    <Carousel
      showDots
      ssr
      slidesToSlide={1}
      responsive={responsive}
      partialVisible
      infinite
      swipeable={false}
      draggable={false}
    >
      {images.map((image, index) => (
        <div key={index.toString()} className="roomImage">
          <img className="img-fluid" src={image.src} alt="" title="" />
        </div>
      ))}
    </Carousel>
  );
};

export default HorizontalRoomViewSlider;
