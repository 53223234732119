import React from "react";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  arrow: false,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: true,
};

const AboutUs: React.FC = () => {
  return (
    <>
      <div className="page-wrapper aboutus-page">
        <div className="container">
          <div className="row wow slideInUp">
            <div className="col-12">
              <h1 className="page-heading">About Us</h1>
            </div>
          </div>
        </div>
        <section className="wow slideInUp">
          <div className="container">
            <div className="about-vision-wrapper wow slideInUp">
              <div className="row">
                <div
                  className="col-md-6 about-text-section wow slideInLeft"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <p>
                    We are your <strong>travel guide</strong> and{" "}
                    <strong>best hotel booking platform</strong>, who helps you{" "}
                    <strong>find the best hotels</strong> and services at{" "}
                    <strong>discounted rates and world class facilities</strong>
                    . We understand the importance of comfort and luxury in your
                    vacation. To make your trip memorable, happy and meaningful,
                    we plan your trips with popular and mind-blowing
                    destinations according to your thoughts and expectations. We
                    not only provide you with luxury hotels at pocket-friendly
                    rates, but also help you to relax your self being by taking
                    care of your aspirations. Your expectations are our
                    priority.
                  </p>
                </div>
                <div
                  className="col-md-6 about-img-section wow slideInRight"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <img
                    className="img-fluid"
                    alt=""
                    src={
                      process.env.PUBLIC_URL + "/assets/images/about-right.png"
                    }
                  />
                </div>
                <div
                  className="col-md-6 about-img-section wow slideInLeft"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <img
                    className="img-fluid"
                    alt=""
                    src={
                      process.env.PUBLIC_URL + "/assets/images/about-left.png"
                    }
                  />
                </div>
                <div
                  className="col-md-6 about-text-section wow slideInRight"
                  data-wow-duration="1500ms"
                  data-wow-delay="300ms"
                >
                  <strong>Our Vision</strong>
                  <p>
                    Provide authentic, trustworthy and dream come true
                    experience of luxury and comfort at the same time, during
                    your vacation. Make every moment of your trip a celebration
                    of happiness and a glory for life time.
                  </p>
                  <strong>Our Mission</strong>
                  <p>
                    To be the first choice when it comes to trust and
                    expectation for our consumers. To plan and make{" "}
                    <strong>online market of booking and reservations</strong> a
                    big boom in the modern culture, with remarkable services and
                    a life time asset to our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about-teme">
            <div className="container">
              <h2>Meet our Team</h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="team-slider-wrapper">
                    <Slider {...settings}>
                      <div className="team-slide">
                        <div className="team-user-img">
                          <img
                            className="img-fluid"
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/default-user-img.png"
                            }
                          />
                        </div>
                        <div className="team-user-position">Group CEO</div>
                        <div className="team-user-name">Sohan Singh</div>
                        <p>
                          A serial entrepreneur, Dhruv has started and
                          successfully exited several ventures across a wide
                          spectrum of domains including education, mining and
                          fintech. Dhruv co-founded iTrust Financial Advisors,
                          an online financial advisory and distribution company
                          which was bought by Karvy Private Wealth. Prior to
                          this, he was the CEO of General Electric’s (GE)
                          infrastructure business in India and also led the
                          Institutional Sales function for GE in India.
                        </p>
                      </div>
                      <div className="team-slide">
                        <div className="team-user-img">
                          <img
                            className="img-fluid"
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/default-user-img.png"
                            }
                          />
                        </div>
                        <div className="team-user-position">Group CEO</div>
                        <div className="team-user-name">Sohan Singh</div>
                        <p>
                          A serial entrepreneur, Dhruv has started and
                          successfully exited several ventures across a wide
                          spectrum of domains including education, mining and
                          fintech. Dhruv co-founded iTrust Financial Advisors,
                          an online financial advisory and distribution company
                          which was bought by Karvy Private Wealth. Prior to
                          this, he was the CEO of General Electric’s (GE)
                          infrastructure business in India and also led the
                          Institutional Sales function for GE in India.
                        </p>
                      </div>
                      <div className="team-slide">
                        <div className="team-user-img">
                          <img
                            className="img-fluid"
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/default-user-img.png"
                            }
                          />
                        </div>
                        <div className="team-user-position">Group CEO</div>
                        <div className="team-user-name">Sohan Singh</div>
                        <p>
                          A serial entrepreneur, Dhruv has started and
                          successfully exited several ventures across a wide
                          spectrum of domains including education, mining and
                          fintech. Dhruv co-founded iTrust Financial Advisors,
                          an online financial advisory and distribution company
                          which was bought by Karvy Private Wealth. Prior to
                          this, he was the CEO of General Electric’s (GE)
                          infrastructure business in India and also led the
                          Institutional Sales function for GE in India.
                        </p>
                      </div>
                      <div className="team-slide">
                        <div className="team-user-img">
                          <img
                            className="img-fluid"
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/default-user-img.png"
                            }
                          />
                        </div>
                        <div className="team-user-position">Group CEO</div>
                        <div className="team-user-name">Sohan Singh</div>
                        <p>
                          A serial entrepreneur, Dhruv has started and
                          successfully exited several ventures across a wide
                          spectrum of domains including education, mining and
                          fintech. Dhruv co-founded iTrust Financial Advisors,
                          an online financial advisory and distribution company
                          which was bought by Karvy Private Wealth. Prior to
                          this, he was the CEO of General Electric’s (GE)
                          infrastructure business in India and also led the
                          Institutional Sales function for GE in India.
                        </p>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <img
                    className="img-fluid m-auto d-block"
                    alt=""
                    src={
                      process.env.PUBLIC_URL + "/assets/images/about-team.png"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
