import React from "react";
import { Link, useLocation } from "react-router-dom";
import ForgotModal from "../modals/ForgotModal";
import FooterCitiesSection from "./components/FooterCitiesSection";

const Footer: React.FC = () => {
  const location = useLocation();

  if (location.pathname === "/online-payment") {
    return null;
  }

  return (
    <>
      <footer>
        <div className="wow slideInUp">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="footer-section1 wow slideInUp">
                  Join our network and grow your business!
                </div>
              </div>
            </div>
            <div className="footer-section2 wow slideInUp">
              <div className="row">
                <div className="col-md-2 d-flex">
                  <div className="footer-logo my-auto ml-0">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/logo-white.png"
                      }
                      alt="Silver Stays Logo"
                    />
                  </div>
                </div>
                <div className="col-md-5 d-flex">
                  <div className="footer-app-wrapper text-center m-auto">
                    <h2 className="my-2">
                      Download now Silver Stays app and get many offers.
                    </h2>
                    <div>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.silverstays"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Google_Play.png"
                          }
                          alt="Google Play Store"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/silver-stays/id1554467934"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Apple_store.png"
                          }
                          alt="Apple App Store"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <ul className="main-page-menu-list">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/cancellation-policy">Cancellation Policy</Link>
                    </li>
                    <li>
                      <Link to="/support">Support</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/guest-policy">Guest Policies</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <FooterCitiesSection />
            <div className="row footer-section4 wow slideInUp">
              <div className="col-md-4 d-flex">
                <ul className="footer-socialmedia-icons">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/silverstaysindore"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="socialImage"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/facebook.svg"
                        }
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/silver-stays/"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="socialImage"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/linkedin.svg"
                        }
                        alt="LinkedIn"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/silverstays/"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="socialImage"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/insta.svg"
                        }
                        alt="Instagram"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="footer-payment-icons">
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/mastercard.png"
                      }
                      alt="Mastercard"
                    />
                  </li>
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/maestro.png"
                      }
                      alt="Maestro"
                    />
                  </li>
                  <li>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/visa.png"}
                      alt="Visa"
                    />
                  </li>
                  <li>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/americanexpress.png"
                      }
                      alt="American Express"
                    />
                  </li>
                  <li>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/paypal.png"}
                      alt="PayPal"
                    />
                  </li>
                </ul>
              </div>
              <div className="col-md-4 d-flex">
                <p className="">2020 Copyright Silver Stays</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ForgotModal
        show={false}
        ToggleModal={() => {}}
        ToggleVerifyModal={() => {}}
      />
    </>
  );
};

export default Footer;
