import * as geolib from "geolib";
import { UPDATE_FILTER } from "../Reducers/FilterView/FilterReducer";
import { amenitiesFind } from "./helper";

interface Position {
  latitude: number;
  longitude: number;
}

interface Room {
  actual_price: number;
  room_cate_id: number;
}

interface Hotel {
  lat: number;
  lng: number;
  distance?: number;
  rooms: string;
  avg_rating: number;
  amenities: string;
}

export const orderByNearestLocation = (
  position: Position,
  hotels: Hotel[]
): Hotel[] => {
  const newHotelArray: Hotel[] = [];
  const fromPosition = {
    latitude: position.latitude,
    longitude: position.longitude,
  };

  hotels.forEach((hotel) => {
    const toPosition = {
      latitude: hotel.lat,
      longitude: hotel.lng,
    };
    const distance = geolib.getDistance(fromPosition, toPosition);
    hotel.distance = distance;
    newHotelArray.push(hotel);
  });

  newHotelArray.sort((a, b) => {
    return a.distance! - b.distance!;
  });

  return newHotelArray;
};

export const MinMaxPrice = (hotels: Hotel[]): { min: number; max: number } => {
  let price = {
    min: Infinity,
    max: 0,
  };

  hotels.forEach((hotel) => {
    let rooms: Room[] = JSON.parse(hotel.rooms);
    rooms.forEach((room) => {
      if (Number(room.actual_price) < price.min) {
        price.min = room.actual_price;
      }
      if (Number(room.actual_price) > price.max) {
        price.max = room.actual_price;
      }
    });
  });

  return price;
};

export const SortByRating = (hotels: Hotel[]): Hotel[] => {
  let newHotels = [...hotels];
  newHotels.sort((a, b) => {
    return b.avg_rating - a.avg_rating;
  });
  return newHotels;
};

const sortByRoomPrice = (hotel: Hotel, order: "asc" | "desc"): Hotel => {
  let rooms: Room[] = JSON.parse(hotel.rooms);
  rooms.sort((r1, r2) => {
    return order === "asc"
      ? r1.actual_price - r2.actual_price
      : r2.actual_price - r1.actual_price;
  });
  hotel.rooms = JSON.stringify(rooms);
  return hotel;
};

export const PriceHightToLow = (hotels: Hotel[]): Hotel[] => {
  let newHotels = [...hotels];
  newHotels.sort((a, b) => {
    sortByRoomPrice(a, "desc");
    sortByRoomPrice(b, "desc");
    return (
      Number(JSON.parse(b.rooms)[0].actual_price) -
      Number(JSON.parse(a.rooms)[0].actual_price)
    );
  });
  return newHotels;
};

export const PriceLowToHigh = (hotels: Hotel[]): Hotel[] => {
  let newHotels = [...hotels];
  newHotels.sort((a, b) => {
    sortByRoomPrice(a, "asc");
    sortByRoomPrice(b, "asc");
    return (
      Number(JSON.parse(a.rooms)[0].actual_price) -
      Number(JSON.parse(b.rooms)[0].actual_price)
    );
  });
  return newHotels;
};

export const ApplyFilter = (state: any, dispatch: any): void => {
  let newHotels: Hotel[] = [];
  state.originalHotelsList.forEach((hotel: Hotel) => {
    let pricePass = false;
    let guestRating = false;
    let amenities = false;
    let category = false;

    if (state.selectedAmenities.length === 0) {
      amenities = true;
    }

    if (state.selectedCategories.length === 0) {
      category = true;
    }

    let rooms: Room[] = JSON.parse(hotel.rooms);
    rooms.forEach((room) => {
      if (
        room.actual_price >= state.priceValues[0] &&
        room.actual_price <= state.priceValues[1]
      ) {
        pricePass = true;
      }

      if (!category) {
        let find = amenitiesFind(
          Number(room.room_cate_id),
          state.selectedCategories
        );
        if (find) {
          category = true;
        }
      }
    });

    if (!amenities) {
      let hotelAmenities = JSON.parse(hotel.amenities);
      if (hotelAmenities.length > 0) {
        state.selectedAmenities.forEach((selectAmenity: number) => {
          let find = amenitiesFind(selectAmenity, hotelAmenities);
          if (find) {
            amenities = true;
          }
        });
      }
    }

    if (state.guestRating) {
      if (hotel.avg_rating > 3) {
        guestRating = true;
      } else {
        guestRating = false;
      }
    } else {
      guestRating = true;
    }

    if (pricePass && guestRating && amenities && category) {
      newHotels.push(hotel);
    }
  });

  dispatch({
    type: UPDATE_FILTER,
    payload: {
      filtersHotels: newHotels,
    },
  });
};
