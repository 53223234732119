import React, { useState } from "react";
// import UserBooking from "../Components/Profile/UserBooking";
import ProfileSideSection from "../../components/profile/ProfileSideSection";
import UpdateProfile from "../../components/profile/UpdateProfile";
import UserBooking from "../../components/profile/UserBooking";
import UserDashboard from "../../components/profile/UserDashboard";

const Profile = () => {
  const [show, setShow] = useState<"info" | "booking" | "edit">("info");

  return (
    <div className="page-wrapper profile-page">
      <section className="wow slideInUp">
        <div className="container">
          <div className="profilewrapper wow slideInUp">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-menu">
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          show === "info" ? "active" : ""
                        }`}
                        data-toggle="pill"
                        href="#home"
                        onClick={(e) => {
                          e.preventDefault();
                          setShow("info");
                        }}
                      >
                        My Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          show === "booking" ? "active" : ""
                        }`}
                        data-toggle="pill"
                        href="#menu1"
                        onClick={(e) => {
                          e.preventDefault();
                          setShow("booking");
                        }}
                      >
                        Bookings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          show === "edit" ? "active" : ""
                        }`}
                        data-toggle="pill"
                        href="#menu2"
                        onClick={(e) => {
                          e.preventDefault();
                          setShow("edit");
                        }}
                      >
                        Edit Profile
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 wow slideInLeft">
                <ProfileSideSection />
              </div>
              <div className="col-md-9 wow slideInRight">
                <div className="profile-details-wrapper">
                  <div className="tab-content">
                    {show === "info" ? (
                      <div>
                        <UserDashboard />
                      </div>
                    ) : show === "booking" ? (
                      <div>
                        <UserBooking />
                      </div>
                    ) : (
                      <div>
                        <UpdateProfile />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
