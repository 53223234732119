import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { connect, ConnectedProps } from "react-redux";
import Api from "../../services/api.service";
import { userThunkLogin } from "../../redux-store/actions/userAction";

interface VerifyOtpModalProps extends PropsFromRedux {
  show: boolean;
  ToggleVerifyModal: () => void;
}

const VerifyOtpModal: React.FC<VerifyOtpModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [FormErrors, setFormErrors] = useState<string>("");
  const [FormSuccess, setFormSuccess] = useState<string>("");

  const handleSubmit = () => {
    setLoading(true);
    if (otp === "") {
      setFormErrors("Please Enter OTP");
      setLoading(false);
      return;
    }

    const mobile = localStorage.getItem("usermobile");
    const formData = new FormData();
    formData.append("mobile", mobile || "");
    formData.append("code", otp);

    Api.post("/otp/verify_user", formData)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.status === "success") {
          localStorage.removeItem("usermobile");
          const usertoken = `${data.token_id}:${data.token}`;
          props.login(usertoken);
          props.ToggleVerifyModal();
        } else {
          setFormErrors(res.data.msg);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        animation={true}
        centered
        className="modal-wrapper"
        dialogClassName="w-35"
        show={props.show}
        onHide={props.ToggleVerifyModal}
      >
        <div>
          <button
            type="button"
            className="close"
            onClick={props.ToggleVerifyModal}
          >
            &times;
          </button>
          <h1 className="modaltitle">Verify OTP</h1>
        </div>

        {FormSuccess && <Alert variant="success">{FormSuccess}</Alert>}
        {FormErrors && <Alert variant="danger">{FormErrors}</Alert>}
        <div>
          <OtpInput
            isInputNum
            containerStyle="otp-input-container"
            inputStyle="otp-input"
            value={otp}
            onChange={(otp: string) => setOtp(otp)}
            numInputs={4}
          />
        </div>
        <div className="form-group submit-btn">
          <input onClick={handleSubmit} type="button" value="SUBMIT" name="" />
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (token: string) => dispatch(userThunkLogin(token, "user")),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VerifyOtpModal);
