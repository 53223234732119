import React from "react";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";
import { amenitiesFind } from "../../helper/helper";

interface Amenity {
  id: number;
  amenity: string;
}

interface FilterState {
  amenities: Amenity[];
  selectedAmenities: number[];
}

const AmenitiesFilter: React.FC = () => {
  const { state, dispatch } = React.useContext(FilterContext) as {
    state: FilterState;
    dispatch: React.Dispatch<{ type: string; payload: any }>;
  };

  if (state.amenities.length === 0) {
    return null;
  }

  const addAmenities = (list: Amenity, find: boolean) => {
    let newUpdate = [...state.selectedAmenities];
    if (find) {
      newUpdate = newUpdate.filter((item) => Number(item) !== Number(list.id));
    } else {
      newUpdate.push(list.id);
    }
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        selectedAmenities: newUpdate,
      },
    });
  };

  return (
    <section className="sidebar-box sidebar-Amenities-section">
      <h6>Amenities</h6>
      <div className="form-group amenities_wrapper">
        {state.amenities.map((list, index) => {
          const find =
            state.selectedAmenities.length > 0
              ? amenitiesFind(list.id, state.selectedAmenities)
              : false;
          return (
            <label key={index.toString()}>
              <input
                checked={find as any}
                onClick={() => addAmenities(list, find as any)}
                className="checkbox"
                type="checkbox"
              />
              <span>{list.amenity}</span>
            </label>
          );
        })}
      </div>
    </section>
  );
};

export default AmenitiesFilter;
