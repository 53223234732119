import React from "react";
import Slider from "react-slick";
import { CONSTANTS } from "../../utils/Constants";

interface Image {
  room_image: string;
  room_image_tag: string;
}

interface HotelImage {
  hotel_image: string;
  hotel_image_tag: string;
}

interface RoomImageSliderProps {
  images: any;
  hotelImages: any;
}

const settings = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
};

function RoomImageSlider(props: RoomImageSliderProps) {
  let images: { src: string; title: string }[] = [];

  try {
    const hotelRaw: HotelImage[] = JSON.parse(props.hotelImages);
    hotelRaw.forEach((image) => {
      images.push({
        src: `${CONSTANTS.IMAGE_BASE_URL}/hotels/${image.hotel_image}`,
        title: `${image.hotel_image_tag}`,
      });
    });
  } catch (error) {
    console.error("Error parsing hotel images:", error);
  }

  try {
    const imagesRaw: Image[] = JSON.parse(props.images);
    imagesRaw.forEach((image) => {
      images.push({
        src: `${CONSTANTS.IMAGE_BASE_URL}/rooms/${image.room_image}`,
        title: `${image.room_image_tag}`,
      });
    });
  } catch (error) {
    console.error("Error parsing room images:", error);
  }

  if (!images || images.length <= 1) {
    return (
      <div
        style={{
          display: "block",
          background: "#f1f1f1",
          textAlign: "center",
        }}
      >
        <img src={CONSTANTS.DAFAULT_ROOM_IMAGE} className={"image-fluid"} />
      </div>
    );
  }

  return (
    <div className="property-view-slider">
      <Slider {...settings} className="hotel-views-slider">
        {images.map((image, index) => (
          <div key={index.toString()} className="hotel-views-slide">
            <img
              className="img-fluid"
              src={image.src}
              alt={image.title}
              title={image.title}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default RoomImageSlider;
