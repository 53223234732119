import addDays from "date-fns/addDays";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import {
  FILTER_REDUCER,
  initialState,
  UPDATE_FILTER,
} from "../../Reducers/FilterView/FilterReducer";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import PageLoading from "../../components/page-loading/PageLoading";
import { MinMaxPrice, orderByNearestLocation } from "../../helper/searchHelper";
import FilterSection from "../../components/filter/FilterSection";
import FiltersResult from "../../components/filter/FiltersResult";

interface QueryParams {
  type: string;
  query: string;
  checkin: string;
  checkout: string;
  rooms: number;
  guests: number;
  city_id: number;
}

const defaultQueryParams: QueryParams = {
  type: "",
  query: "",
  checkin: moment().format("YYYY-MM-DD"),
  checkout: moment().add(1, "day").format("YYYY-MM-DD"),
  rooms: 1,
  guests: 1,
  city_id: 2229,
};

const Search: React.FC = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [state, dispatch] = useReducer(FILTER_REDUCER, initialState);
  const { filter } = useParams<{ filter?: string }>();
  const [queryParsms, setQueryParams] =
    useState<QueryParams>(defaultQueryParams);
  const location = useLocation();

  const loadAmenities = useCallback(async () => {
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      const res = await Api.post(APIURLS.GETALLAMENITIES, formData);
      const responseData = res.data;
      if (responseData.status === "success") {
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            amenities: responseData.data,
          },
        });
      }
    } catch (error) {
      console.error("Error loading amenities:", error);
    }
  }, []);

  const loadCategories = useCallback(async () => {
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      const res = await Api.post(APIURLS.ALL_ROOM_CATEGORY, formData);
      const responseData = res.data;
      if (responseData.status === "success") {
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            categories: responseData.data,
          },
        });
      }
    } catch (error) {
      console.error("Error loading categories:", error);
    }
  }, []);

  const setQueryParamsFromLocation = useCallback(() => {
    const queryParams: QueryParams = { ...defaultQueryParams };
    const params = new URLSearchParams(location.search);
    queryParams.type = params.get("type") || "";
    queryParams.query = params.get("query") || "";
    if (params.get("checkin")) {
      queryParams.checkin = moment(params.get("checkin")).format("YYYY-MM-DD");
    }
    if (params.get("checkout")) {
      queryParams.checkout = moment(params.get("checkout")).format(
        "YYYY-MM-DD"
      );
    }
    queryParams.rooms = Number(params.get("rooms")) || 1;
    queryParams.guests = Number(params.get("guests")) || 1;
    queryParams.city_id = Number(params.get("city_id")) || 2229;
    setQueryParams(queryParams);
  }, [location.search]);

  useEffect(() => {
    loadAmenities();
    setQueryParamsFromLocation();
    loadCategories();
  }, [setQueryParamsFromLocation, loadAmenities, loadCategories]);

  const getHotels = useCallback(async () => {
    const formData = new FormData();
    formData.append("city", queryParsms.city_id.toString());
    formData.append("filter", JSON.stringify(queryParsms));
    let position: GeolocationCoordinates | null = null;
    navigator.geolocation.getCurrentPosition((currentLocation) => {
      position = currentLocation.coords;
    });

    setDataLoading(true);

    try {
      const res = await Api.post(APIURLS.GETCITYHOTELS, formData);
      const data = res.data;
      if (data.status === "success") {
        setDataLoading(false);
        const allHotels = data.data;
        // Calculate Min Max Price
        let minMaxPrice = MinMaxPrice(allHotels);
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            minPrice: Number(minMaxPrice.min),
            maxPrice: Number(minMaxPrice.max),
            priceValues: [Number(minMaxPrice.min), Number(minMaxPrice.max)],
            originalHotelsList: allHotels,
            filtersHotels: allHotels,
            // position: position,
            orderByNearest: allHotels,
          },
        });
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      console.error("Error fetching hotels:", error);
      setDataLoading(false);
    }
  }, [queryParsms]);

  const getNearByHotels = useCallback(async () => {
    const formData = new FormData();
    formData.append("city", queryParsms.city_id.toString());
    formData.append("filter", JSON.stringify(queryParsms));

    setDataLoading(true);

    try {
      navigator.geolocation.getCurrentPosition((position) => {
        Api.post(APIURLS.GETCITYHOTELS, formData)
          .then((res) => {
            const data = res.data;
            if (data.status === "success") {
              let orderByNearest = orderByNearestLocation(
                position.coords,
                data.data
              );
              // Calculate Min Max Price
              let minMaxPrice = MinMaxPrice(orderByNearest);
              dispatch({
                type: UPDATE_FILTER,
                payload: {
                  minPrice: Number(minMaxPrice.min),
                  maxPrice: Number(minMaxPrice.max),
                  priceValues: [
                    Number(minMaxPrice.min),
                    Number(minMaxPrice.max),
                  ],
                  originalHotelsList: orderByNearest,
                  filtersHotels: orderByNearest,
                  position: position.coords,
                  orderByNearest,
                },
              });
              setDataLoading(false);
            } else {
              setDataLoading(false);
            }
          })
          .catch(() => {
            setDataLoading(false);
          });
      });
    } catch (error) {
      console.error("Error fetching nearby hotels:", error);
      setDataLoading(false);
    }
  }, [queryParsms]);

  useEffect(() => {
    if (filter !== undefined && filter === "nearby") {
      getNearByHotels();
    } else {
      getHotels();
    }
  }, [queryParsms, location.search, getNearByHotels, getHotels, filter]);

  if (dataLoading) {
    return <PageLoading />;
  } else {
    return (
      <FilterContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <section className="whole-search-wrapper my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3 filter-border-right">
                <FilterSection />
              </div>
              <div className="col-md-8 col-lg-8">
                <div className="short-filter-wrapper ">
                  <h1>Silver STAYS</h1>
                </div>
                <FiltersResult />
              </div>
            </div>
          </div>
        </section>
      </FilterContext.Provider>
    );
  }
};

export default Search;
