import React, { useRef, useState, FormEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
// import { RootState } from "../store"; // Import your RootState type from your store
import Api from "../../services/api.service";

type SupportProps = ConnectedProps<typeof connector>;

const Support: React.FC<SupportProps> = ({ userData }) => {
  const [error, setError] = useState<Record<string, string>>({});
  const [success, setSuccess] = useState("");
  const settings = userData.settings;
  const myform = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError({});
    setSuccess("");
    const token = localStorage.getItem("token");
    const formdata = new FormData(e.target as HTMLFormElement);
    formdata.append("token", token || "");
    formdata.append("subject", (e.target as any).subject.value);
    formdata.append("message", (e.target as any).message.value);
    formdata.append("booking_id", (e.target as any).booking_id.value);

    try {
      const res = await Api.post("need_help", formdata);
      if (res.data.status === "success") {
        setSuccess(res.data.msg);
        myform.current?.reset();
      } else {
        setError(res.data.formErrors);
      }
    } catch (error) {
      console.error("An error occurred while submitting the form", error);
      setError({ general: "An error occurred. Please try again later." });
    }
  };

  return (
    <div className="page-wrapper support-page">
      <div className="container">
        <div className="row wow slideInUp">
          <div className="col-12">
            <h1 className="page-heading">Support</h1>
          </div>
        </div>
      </div>
      <section className="theme-bg wow slideInUp">
        <div className="container">
          <div className="enquiry-faq-wrapper">
            <div className="row">
              <div
                className="col-md-6 wow slideInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="enquiry-wrapper">
                  <ul className="enquiry-contant-list">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                    </li>
                    <li>ENQUIRY</li>
                    <li>
                      <a
                        className="text-white"
                        href={`tel: ${settings.user_help_mobile}`}
                      >
                        {settings.user_help_mobile}
                      </a>
                    </li>
                  </ul>
                  <img
                    alt="support"
                    className="enquiry-img"
                    src={process.env.PUBLIC_URL + "/assets/images/enquiry.png"}
                  />
                </div>
              </div>
              <div
                className="col-md-6 wow slideInRight"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="faq-wrapper">
                  <ul className="faq-contant-list">
                    <li>
                      <i className="fas fa-search"></i>
                    </li>
                    <li>
                      <a href="/#">FAQ</a>
                    </li>
                  </ul>
                  <img
                    alt="support"
                    className="faq-img"
                    src={process.env.PUBLIC_URL + "/assets/images/faq.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="support-bgimg wow slideInUp">
        <div className="help-wrapper">
          <div
            className="help-wrapper-inner wow slideInUp"
            data-wow-duration="1500ms"
            data-wow-delay="300ms"
          >
            <h1 className="help-form-heading">NEED HELP</h1>

            <form ref={myform} onSubmit={handleSubmit} className="help-form">
              {success && (
                <div
                  className="valid-feedback d-block text-center my-2"
                  style={{ fontSize: 15 }}
                >
                  {success}
                </div>
              )}
              <div className="form-group">
                <span className="help-form-icons">
                  <i className="fas fa-envelope"></i>
                </span>
                <input
                  className="form-control"
                  type="text"
                  name="subject"
                  placeholder="Enter Subject"
                />
                {error.subject && (
                  <div className="invalid-feedback d-block">
                    {error.subject}
                  </div>
                )}
              </div>
              <div className="form-group">
                <span className="help-form-icons">
                  <i className="fas fa-calendar-alt"></i>
                </span>
                <input
                  className="form-control"
                  type="text"
                  name="booking_id"
                  placeholder="Booking Id"
                />
                {error.booking_id && (
                  <div className="invalid-feedback d-block">
                    {error.booking_id}
                  </div>
                )}
              </div>
              <div className="form-group">
                <span className="help-form-icons">
                  <i className="fas fa-comment-alt"></i>
                </span>
                <textarea
                  name="message"
                  className="form-control"
                  rows={5}
                  placeholder="Enter your message.........."
                ></textarea>
                {error.message && (
                  <div className="invalid-feedback d-block">
                    {error.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input className="submit-btn" type="submit" value="SUBMIT" />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);

export default connector(Support);
