import React, { useRef, useState, useEffect, FormEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import PageLoading from "../../components/page-loading/PageLoading";
// import { RootState } from "../store"; // Adjust the path to your store

interface Settings {
  address: string;
  user_help_mobile: string;
  mobile: string;
  email: string;
}

interface Errors {
  full_name?: string;
  email?: string;
  mobile?: string;
  message?: string;
}

const ContactUs: React.FC<PropsFromRedux> = () => {
  const [error, setError] = useState<Errors>({});
  const [success, setSuccess] = useState<string>("");
  const myForm = useRef<HTMLFormElement>(null);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const formData = new FormData();
      const res = await Api.post(APIURLS.GET_SETTINGS, formData);
      const responseData = res.data;
      setLoading(false);
      if (responseData.status === "success") {
        setSettings(responseData.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError({});
    setSuccess("");

    const token = localStorage.getItem("token") || "";
    const formData = new FormData(e.currentTarget);
    formData.append("token", token);
    formData.append("full_name", e.currentTarget.full_name.value);
    formData.append("mobile", e.currentTarget.mobile.value);
    formData.append("email", e.currentTarget.email.value);
    formData.append("message", e.currentTarget.message.value);

    try {
      const res = await Api.post("user/add_contact_request", formData);
      if (res.data.status === "success") {
        setSuccess(res.data.msg);
        if (myForm.current) {
          myForm.current.reset();
        }
      } else {
        setError(res.data.formErrors);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="page-wrapper contactus-page">
        <div className="container">
          <div className="row wow slideInUp">
            <div className="col-12">
              <h1 className="page-heading">Contact Us</h1>
            </div>
          </div>
        </div>
        <section className="contact-bgimg wow slideInUp">
          <div className="container">
            <div className="contact-wrapper">
              <div className="row">
                <div className="col-md-6 right-border">
                  <div className="contact-left-wrapper">
                    <h1 className="contact-heading">Contact Us</h1>
                    {success && (
                      <div className="valid-feedback d-block text-center">
                        {success}
                      </div>
                    )}
                    <form
                      onSubmit={handleSubmit}
                      ref={myForm}
                      className="help-form"
                    >
                      <div className="form-group">
                        <span className="help-form-icons">
                          <i className="fas fa-user-circle"></i>
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          name="full_name"
                          placeholder="Full Name"
                        />
                        {error.full_name && (
                          <div className="invalid-feedback d-block">
                            {error.full_name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <span className="help-form-icons">
                          <i className="fas fa-envelope"></i>
                        </span>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email Id"
                        />
                        {error.email && (
                          <div className="invalid-feedback d-block">
                            {error.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <span className="help-form-icons">
                          <i className="fas fa-phone-square-alt"></i>
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          name="mobile"
                          placeholder="Mobile Number"
                        />
                        {error.mobile && (
                          <div className="invalid-feedback d-block">
                            {error.mobile}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <span className="help-form-icons">
                          <i className="fas fa-comment-alt"></i>
                        </span>
                        <textarea
                          name="message"
                          className="form-control"
                          rows={8}
                          placeholder="Enter your message.........."
                        ></textarea>
                        {error.message && (
                          <div className="invalid-feedback d-block">
                            {error.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <button className="submit-btn" type="submit">
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-right-wrapper">
                    <h1 className="contact-heading">Address Info</h1>
                    {settings && (
                      <ul className="contactus-details">
                        <li>
                          <i className="fas fa-map-marker-alt"></i>
                          <strong>Address :</strong> {settings.address}
                        </li>
                        <li>
                          <i className="fas fa-phone-volume"></i>{" "}
                          <strong>Tell :</strong> {settings.user_help_mobile}
                        </li>
                        <li>
                          <i className="fas fa-phone-square-alt"></i>{" "}
                          <strong>Mobile. No :</strong> {settings.mobile}
                        </li>
                        <li>
                          <i className="fas fa-envelope"></i>{" "}
                          <strong>Email Id :</strong> {settings.email}
                        </li>
                      </ul>
                    )}
                    <div className="contact-followus">
                      <h2>Follow : </h2>
                      <a href="/#">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/facebook.svg"
                          }
                        />
                      </a>
                      <a href="/#">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/twitter.png"
                          }
                        />
                      </a>
                      <a href="/#">
                        <img
                          alt=""
                          src={
                            process.env.PUBLIC_URL + "/assets/images/insta.png"
                          }
                        />
                      </a>
                    </div>
                    <div className="map">
                      <iframe
                        title="contact form"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235527.4544711134!2d75.72376282441803!3d22.723911730667293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1600844971652!5m2!1sen!2sin"
                        height="260"
                        frameBorder="0"
                        style={{ border: 0, width: "100%" }}
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex={0}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContactUs);
