import React, {
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
} from "react";
import Agreement from "../../assets/doc/aggrement.docx";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import ApiRequest from "../../services/ApiRequest.service";
import PageLoading from "../../components/page-loading/PageLoading";
import ListPropertyModal from "../../container/modals/ListPropertyModal";

interface RegionLoading {
  state: boolean;
  cities: boolean;
}

interface State {
  id: number;
  name: string;
  iso2: string;
}

interface City {
  id: number;
  name: string;
}

interface Settings {
  vendor_support_mobile: string;
  vendor_support_email: string;
  [key: string]: any;
}

interface FormErrors {
  [key: string]: string;
}

let regionsApiHeader = {
  "content-type": "application/json",
  "X-CSCAPI-KEY": "S1I5STlyb2VtUHR0ZXdSVThvajN6aXVSQWNNNDBvQ1VUN2J4MTd5cQ==",
};

const ListYourProperty: React.FC = () => {
  const [error, setError] = useState<FormErrors>({});
  const [success, setSuccess] = useState<string>("");
  const myForm = useRef<HTMLFormElement | null>(null);

  const [settings, setSettings] = useState<Settings | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const toggleModal = () => setShow(!show);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");

  const [regionLoading, setRegionLoading] = useState<RegionLoading>({
    state: false,
    cities: false,
  });

  const loadStates = async () => {
    setRegionLoading({
      state: true,
      cities: false,
    });

    try {
      const response = await Api.get(
        "https://api.countrystatecity.in/v1/countries/IN/states",
        { headers: regionsApiHeader }
      );

      if (response.status === 200) {
        response.data.sort((a: State, b: State) => (a.name > b.name ? 1 : -1));
        setStates(response.data);
      } else {
        setStates([]);
      }
    } catch (error) {
      setStates([]);
    } finally {
      setRegionLoading({
        state: false,
        cities: false,
      });
    }
  };

  const loadCities = async (stateIso: string) => {
    setRegionLoading({
      state: false,
      cities: true,
    });

    try {
      const response = await Api.get(
        `https://api.countrystatecity.in/v1/countries/IN/states/${stateIso}/cities`,
        { headers: regionsApiHeader }
      );

      if (response.status === 200) {
        response.data.sort((a: City, b: City) => (a.name > b.name ? 1 : -1));
        setCities(response.data);
      } else {
        setCities([]);
      }
    } catch (error) {
      setCities([]);
    } finally {
      setRegionLoading({
        state: false,
        cities: false,
      });
    }
  };

  const onChangeState = (e: ChangeEvent<HTMLSelectElement>) => {
    setCity("");
    let selectedState = states.filter((item) => item.iso2 === e.target.value);
    selectedState.length > 0 ? setState(selectedState[0].name) : setState("");
    if (e.target.value !== "") {
      loadCities(e.target.value);
    }
  };

  useEffect(() => {
    loadSettings();
    loadStates();
  }, []);

  const loadSettings = async () => {
    let formData = new FormData();
    ApiRequest({
      url: APIURLS.GET_SETTINGS,
      values: formData,
      loading: setLoading,
      onSuccessCallback: (data: any) => {
        setSettings(data.data);
      },
      onError: () => {
        setSettings(undefined);
      },
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError({});
    setSuccess("");

    const formData = new FormData();

    formData.append(
      "full_name",
      (e.currentTarget.full_name as HTMLInputElement).value
    );
    formData.append(
      "property_name",
      (e.currentTarget.property_name as HTMLInputElement).value
    );
    formData.append(
      "mobile",
      (e.currentTarget.mobile as HTMLInputElement).value
    );
    formData.append("email", (e.currentTarget.email as HTMLInputElement).value);
    formData.append("state", state);
    formData.append(
      "street",
      (e.currentTarget.street as HTMLInputElement).value
    );
    formData.append("city", city);
    formData.append(
      "pincode",
      (e.currentTarget.pincode as HTMLInputElement).value
    );
    formData.append(
      "address",
      (e.currentTarget.address as HTMLTextAreaElement).value
    );

    Api.post("list_your_property", formData).then((res) => {
      if (res.data["status"] === "success") {
        setRequestId(res.data["data"]);
        setSuccess(res.data["msg"]);
        myForm.current?.reset();
        setStep(2);
      } else {
        setError(res.data["formErrors"]);
      }
    });
  };

  const onModalSuccess = (res: any) => {
    toggleModal();
    setStep(1);
    setSuccess(res.msg);
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <div className="page-wrapper list-property-page wow slideInUp">
        {step === 1 && (
          <form ref={myForm} onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="text-center">List Your Property</h1>
                  <div className="propertylist-form">
                    {success !== "" && (
                      <div className="valid-feedback d-block text-center">
                        {success}
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="full_name"
                          />
                          <div className="invalid-feedback d-block">
                            {error.full_name}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Contact Number</label>
                          <input
                            className="form-control"
                            type="text"
                            name="mobile"
                          />
                          <div className="invalid-feedback d-block">
                            {error.mobile}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                          />
                          <div className="invalid-feedback d-block">
                            {error.email}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name of Property</label>
                          <input
                            className="form-control"
                            type="text"
                            name="property_name"
                          />
                          <div className="invalid-feedback d-block">
                            {error.property_name}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>State</label>
                          <select
                            onChange={onChangeState}
                            name="state"
                            className="form-control"
                          >
                            {regionLoading.state ? (
                              <option value="">Loading....</option>
                            ) : (
                              <option value="">Select State</option>
                            )}
                            {!regionLoading.state &&
                              states.length > 0 &&
                              states.map((state) => {
                                return (
                                  <option key={state.id} value={state.iso2}>
                                    {state.name}
                                  </option>
                                );
                              })}
                          </select>
                          <div className="invalid-feedback d-block">
                            {error.state}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <select
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            name="city"
                            className="form-control"
                          >
                            {regionLoading.cities ? (
                              <option value="">Loading....</option>
                            ) : (
                              <option value="">Select City</option>
                            )}
                            {!regionLoading.cities &&
                              cities.length > 0 &&
                              cities.map((city) => {
                                return (
                                  <option key={city.id} value={city.name}>
                                    {city.name}
                                  </option>
                                );
                              })}
                          </select>
                          <div className="invalid-feedback d-block">
                            {error.city}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Street</label>
                          <input
                            className="form-control"
                            type="text"
                            name="street"
                          />
                          <div className="invalid-feedback d-block">
                            {error.street}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Zip code</label>
                          <input
                            className="form-control"
                            type="text"
                            name="pincode"
                          />
                          <div className="invalid-feedback d-block">
                            {error.pincode}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Address</label>
                          <textarea
                            className="form-control"
                            name="address"
                            rows={3}
                          />
                          <div className="invalid-feedback d-block">
                            {error.address}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="call-request" type="submit">
                          Next Step
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="propertylist-contact">
                    <ul>
                      <li>
                        <a
                          className="text-white"
                          href={`tel: ${settings?.vendor_support_mobile}`}
                        >
                          <i className="fas fa-phone-alt"></i>{" "}
                          {settings?.vendor_support_mobile}
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-white"
                          href={`mailTo: ${settings?.vendor_support_email}`}
                        >
                          <i className="fas fa-envelope"></i>{" "}
                          {settings?.vendor_support_email}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {step === 2 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center">List Your Property</h1>
                <div className="propertylist-form">
                  {success !== "" && (
                    <div className="valid-feedback d-block text-center">
                      {success}
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Demo Contract File</label>
                      <div>
                        <a
                          style={{ color: "#fff" }}
                          rel="noreferrer"
                          href={Agreement}
                          target={"_blank"}
                          download
                        >
                          <i
                            style={{ marginRight: 5 }}
                            className="fas fa-download"
                          ></i>
                          Download Demo Contract
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <input
                        onChange={(e) => setAgreement(e.target.checked)}
                        id="agreement"
                        className="agreementInput"
                        type="checkbox"
                        name="vehicle1"
                        value="Bike"
                      />
                      <label className="agreementLabel" htmlFor="agreement">
                        I have read all the terms and conditions
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        onClick={toggleModal}
                        disabled={!agreement}
                        className="call-request"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="propertylist-contact">
                  <ul>
                    <li>
                      <a
                        className="text-white"
                        href={`tel: ${settings?.vendor_support_mobile}`}
                      >
                        <i className="fas fa-phone-alt"></i>{" "}
                        {settings?.vendor_support_mobile}
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white"
                        href={`mailTo: ${settings?.vendor_support_email}`}
                      >
                        <i className="fas fa-envelope"></i>{" "}
                        {settings?.vendor_support_email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ListPropertyModal
        onSuccessCallback={onModalSuccess}
        requestId={requestId}
        show={show}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default ListYourProperty;
