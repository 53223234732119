import { Star } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ApiRequest from "../../services/ApiRequest.service";
import { APIURLS } from "../../services/APIURLS";
import RatingReviewSkeleton from "./RatingReviewSkeleton";
import SingleRatingRow from "../rating-and-review/SingleRatingRow";
import ViewRatingsDrawer from "../rating-and-review/ViewRatingsDrawer";

const useStyles = makeStyles({
  overallRatingSection: {
    border: "1px solid #e7e7e7",
    margin: "20px 0px",
    borderRadius: 5,
  },
  ratingLeftBlock: {
    textAlign: "center",
    padding: 20,
  },
  averageRatingChip: {
    background: "#004588",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 85,
    padding: 5,
    borderRadius: 5,
    fontSize: 20,
    fontWeight: "bold",
    margin: "auto",
  },
  ratingFeedback: {
    fontSize: "13px !important",
    margin: "5px 0px !important",
    fontWeight: 600,
    textTransform: "capitalize",
    color: "#8888",
  },
  totalRatingText: {
    fontSize: "12px !important",
    margin: "5px 0px !important",
    color: "#8888",
    fontWeight: 500,
  },

  ratingRightBlock: {
    padding: 15,
    borderLeft: "1px solid #e7e7e7",
  },
  ratingBarRow: {
    fontSize: "12px ",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    color: "rgba(0,0,0,0.7)",
    margin: "5px 0",
  },
  viewReviewButton: {
    color: "#004588 !important",
    fontWeight: "700 !important",
    fontSize: "15px !important",
    padding: "10px 0 !important",
  },
  barColorPrimary: {
    backgroundColor: "#f3a413 !important",
  },
});

interface Rating {
  AvgRating: number;
  totalRating: number;
  OneStarRating: number;
  TwoStarRating: number;
  ThreeStarRating: number;
  FourStarRating: number;
  FiveStarRating: number;
  maxRating: number;
}

interface Review {
  id: any;
  username: string;
  created_at: string;
  rating: number;
  review: string;
  images: string;
}

const HotelRatingAndReview: React.FC = () => {
  const classes = useStyles();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  const [overallRatings, setOverallRatings] = useState<Rating>({
    AvgRating: 0,
    totalRating: 0,
    OneStarRating: 0,
    TwoStarRating: 0,
    ThreeStarRating: 0,
    FourStarRating: 0,
    FiveStarRating: 0,
    maxRating: 0,
  });

  const { id } = useParams<{ id: string }>();
  const toggleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const getReviews = useCallback(() => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("hotel_id", id ?? "");
    formData.append("limit", "1");
    formData.append("token", token || "");

    ApiRequest({
      loading: setLoading,
      url: APIURLS.GETHOTELREVIEWSRATING,
      values: formData,
      onSuccessCallback: async (res: any) => {
        setReviews(res.data);
        const averageRating = Number(res.average_rating);
        const totalRating = Number(res.totalRatingsReview);
        const firstRating = Number(res.stars_rating["1"]);
        const secondRating = Number(res.stars_rating["2"]);
        const thirdRating = Number(res.stars_rating["3"]);
        const fourthRating = Number(res.stars_rating["4"]);
        const fifthRating = Number(res.stars_rating["5"]);

        const max = Math.max(
          firstRating,
          secondRating,
          thirdRating,
          fourthRating,
          fifthRating
        );

        setOverallRatings({
          AvgRating: averageRating,
          totalRating: totalRating,
          FiveStarRating: fifthRating,
          FourStarRating: fourthRating,
          maxRating: max,
          OneStarRating: firstRating,
          ThreeStarRating: thirdRating,
          TwoStarRating: secondRating,
        });
      },
      onError: () => {
        setReviews([]);
      },
    });
  }, [id]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const overallFeedback = useMemo(() => {
    if (overallRatings.AvgRating <= 1) {
      return "BAD";
    } else if (overallRatings.AvgRating <= 2) {
      return "OK";
    } else if (overallRatings.AvgRating <= 3) {
      return "JUST GOOD";
    } else if (overallRatings.AvgRating <= 4) {
      return "GOOD";
    } else if (overallRatings.AvgRating <= 5) {
      return "VERY GOOD";
    }
  }, [overallRatings.AvgRating]);

  const ratingPercentage = (rating: number) => {
    return (rating / overallRatings.maxRating) * 100;
  };

  if (loading) {
    return <RatingReviewSkeleton />;
  }

  return (
    <>
      <Box my={3}>
        <h2 className="details-sub-heading">Rating And Reviews</h2>

        <Box className={classes.overallRatingSection}>
          <Grid container alignItems={"center"}>
            <Grid item lg={5}>
              <Box className={classes.ratingLeftBlock}>
                <Box className={classes.averageRatingChip}>
                  {overallRatings.AvgRating.toFixed(1)}
                  <Star style={{ fontSize: 16, marginLeft: 3 }} />
                </Box>
                <p className={classes.ratingFeedback}>{overallFeedback}</p>
                <p className={classes.totalRatingText}>
                  {overallRatings.totalRating} Ratings
                </p>
              </Box>
            </Grid>
            <Grid item lg={7}>
              <Box className={classes.ratingRightBlock}>
                {[
                  { label: "5", value: overallRatings.FiveStarRating },
                  { label: "4", value: overallRatings.FourStarRating },
                  { label: "3", value: overallRatings.ThreeStarRating },
                  { label: "2", value: overallRatings.TwoStarRating },
                  { label: "1", value: overallRatings.OneStarRating },
                ].map((rating, index) => (
                  <Box className={classes.ratingBarRow} key={index}>
                    {rating.label}
                    <Star
                      style={{
                        fontSize: 14,
                        marginLeft: 2,
                        color: "rgb(205, 205, 205)",
                      }}
                    />
                    <LinearProgress
                      color="primary"
                      classes={{ barColorPrimary: classes.barColorPrimary }}
                      sx={{
                        width: "100%",
                        marginX: 1,
                        borderRadius: 10,
                        background: "#e8e8e8",
                      }}
                      variant="determinate"
                      value={ratingPercentage(rating.value)}
                    />
                    <span
                      style={{
                        color: "rgba(0,0,0,0.54)",
                        fontWeight: 500,
                      }}
                    >
                      {rating.value}
                    </span>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {reviews.length > 0 &&
          reviews.map((review, index) => (
            <SingleRatingRow review={review} key={index} />
          ))}

        {overallRatings.totalRating > 1 && (
          <Button
            onClick={toggleDrawer}
            disableRipple
            sx={{ textTransform: "capitalize" }}
            className={classes.viewReviewButton}
          >
            See All Reviews
          </Button>
        )}
      </Box>
      <ViewRatingsDrawer open={drawer} toggleDrawer={toggleDrawer} />
    </>
  );
};

export default memo(HotelRatingAndReview);
