import { Box, Skeleton } from "@mui/material";
import React from "react";

const RatingReviewSkeleton = () => {
  return (
    <Box>
      <Box display={"flex"} alignItems="center">
        <Box width={"70%"} p={2}>
          <Skeleton variant="rectangular" width={"100%"} height={100} />
        </Box>
        <Box width={"100%"} p={2}>
          <Skeleton
            sx={{ borderRadius: 10 }}
            variant="rectangular"
            width={"100%"}
            height={8}
          />
          <Skeleton
            sx={{ marginTop: 2, borderRadius: 10 }}
            variant="rectangular"
            width={"100%"}
            height={8}
          />
          <Skeleton
            sx={{ marginTop: 2, borderRadius: 10 }}
            variant="rectangular"
            width={"100%"}
            height={8}
          />
          <Skeleton
            sx={{ marginTop: 2, borderRadius: 10 }}
            variant="rectangular"
            width={"100%"}
            height={8}
          />
          <Skeleton
            sx={{ marginTop: 2, borderRadius: 10 }}
            variant="rectangular"
            width={"100%"}
            height={8}
          />
        </Box>
      </Box>

      <Box p={2} my={1}>
        <Box display={"flex"} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton
            sx={{ marginLeft: 2, borderRadius: 10 }}
            variant="rectangular"
            width={250}
            height={8}
          />
        </Box>
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
      </Box>
      <Box p={2} my={1}>
        <Box display={"flex"} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton
            sx={{ marginLeft: 2, borderRadius: 10 }}
            variant="rectangular"
            width={250}
            height={8}
          />
        </Box>
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
      </Box>
      <Box p={2} my={1}>
        <Box display={"flex"} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton
            sx={{ marginLeft: 2, borderRadius: 10 }}
            variant="rectangular"
            width={250}
            height={8}
          />
        </Box>
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
        <Skeleton
          sx={{ borderRadius: 10, marginTop: 2 }}
          variant="rectangular"
          width={"100%"}
          height={8}
        />
      </Box>
    </Box>
  );
};

export default RatingReviewSkeleton;
