import React from "react";
import { Link, useLocation } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { addDays } from "date-fns";
import moment from "moment";
import { RatingText } from "../../helper/helper";
import AmenitiesWithFilter from "../hotel-view/AmenitiesWithFilter";
import { PadNumber, stringToUrlString } from "../../utils/helpers";
import { CONSTANTS } from "../../utils/Constants";
import HorizontalRoomViewSlider from "./HorizontalRoomViewSlider";

// Define the types for the props and other objects
interface Room {
  actual_price: string;
  cut_off_price: string;
}

interface HotelData {
  id: number;
  hotel_name: string;
  address: string;
  couple_friendly: number;
  avg_rating: number;
  total_rating: number;
  amenities: any[]; // Define a more specific type if available
  room_images: string[];
  hotel_images: string[];
  rooms: string; // Assuming this is a JSON string; you might want to parse it into a more specific type
}

interface HorizontalRoomViewCategoryProps {
  hotelData: HotelData;
}

const HorizontalRoomViewCategory: React.FC<HorizontalRoomViewCategoryProps> = (
  props
) => {
  const room = props.hotelData;
  const singleRoom: Room = JSON.parse(room.rooms)[0];
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let CHECK_IN_DATE: string | Date = new Date();
  let CHECK_OUT_DATE: string | Date = addDays(new Date(), 1);
  let checkin_checkout = false;

  if (params.get("checkin")) {
    CHECK_IN_DATE = moment(params.get("checkin")).format("YYYY-MM-DD");
    checkin_checkout = true;
  }
  if (params.get("checkout")) {
    CHECK_OUT_DATE = moment(params.get("checkout")).format("YYYY-MM-DD");
    checkin_checkout = true;
  }

  params.set("checkin", CHECK_IN_DATE.toString());
  params.set("checkout", CHECK_OUT_DATE.toString());

  const LINK_URL = `/hotel/${stringToUrlString(room.hotel_name)}/${room.id}/0${
    checkin_checkout ? `?${params.toString()}` : ""
  }`;

  const actualPrice = singleRoom.actual_price;
  const cutOffPrice = singleRoom.cut_off_price;
  const discountPercentage =
    ((Number(singleRoom.cut_off_price) - Number(singleRoom.actual_price)) *
      100) /
    Number(singleRoom.cut_off_price);

  return (
    <div>
      <div className="row all-property-listing">
        <div className="col-md-12 col-lg-5 p-0">
          <HorizontalRoomViewSlider
            images={room.room_images as any}
            hotelImages={room.hotel_images as any}
          />
        </div>

        <div className="col-md-12 col-lg-7 p-0">
          <Link to={LINK_URL}>
            <div className="hotel-detalis-wrapper">
              <h1 className="hotelvilahouse-name">
                <img src={CONSTANTS.LOGO} className={"logoBrandingImage"} />
                {PadNumber(room.id, 4)} {room.hotel_name}
              </h1>
              <h4 className="hotelvilahouse-address">{room.address}</h4>
              {Number(room.couple_friendly) === 1 && (
                <div>
                  <span className="badge badge-warning">
                    {"Couple Friendly"}
                  </span>
                </div>
              )}
              <div className="room-rating">
                <span className={"mr-2"}>
                  {Number(room.avg_rating).toFixed(1)}
                  <i className="fas fa-star ml-2"></i>
                </span>
                ({room.total_rating} Ratings) {RatingText(room.total_rating)}
              </div>
              <div className={"aminitiesListing"}>
                <AmenitiesWithFilter roomAmenity={room.amenities} />
              </div>

              <div className="hotelvilahouse-price">
                <span className="main-price">
                  <i className="fas fa-rupee-sign"></i> {actualPrice}
                </span>
                {discountPercentage > 0 && (
                  <>
                    <span className="off-price">
                      <i className="fas fa-rupee-sign"></i> {cutOffPrice}
                    </span>
                    <span className="discount-price">
                      {discountPercentage.toFixed()}{" "}
                      <i className="fas fa-percentage"></i>
                    </span>
                  </>
                )}
              </div>

              {/* <div className="view-booking-btn-section">
                <Link>View Details</Link>
                <Link
                  to={`/hotel/${stringToUrlString(room.hotel_name)}/${
                    room.id
                  }/${"0"}`}
                >
                  Book Now
                </Link>
              </div> */}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HorizontalRoomViewCategory;
