import React, { useEffect, useState, useContext } from "react";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import {
  PriceHightToLow,
  PriceLowToHigh,
  SortByRating,
} from "../../helper/searchHelper";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";

const SortBy: React.FC = () => {
  const { state, dispatch } = useContext(FilterContext) as {
    state: any;
    dispatch: any;
  };
  const [sortBy, setSortBy] = useState<string>("");
  // const sortByState = state.sortBy;

  const sortByFn = async (sortByValue: string) => {
    // Get Lat Long
    // let Currentposition = await GetLocation.getCurrentPosition({
    //   enableHighAccuracy: true,
    //   timeout: 15000,
    // });

    let sortList = state.filtersHotels;
    if (sortByValue === "popularity") {
      sortList = state.orderByNearest;
    } else if (sortByValue === "rating") {
      sortList = SortByRating(state.filtersHotels);
    } else if (sortByValue === "pricelh") {
      sortList = PriceLowToHigh(state.filtersHotels);
    } else if (sortByValue === "pricehl") {
      sortList = PriceHightToLow(state.filtersHotels);
    } else if (sortByValue === "distance") {
      //   sortList = orderByNearestLocation(Currentposition, state.orderByNearest);
    }

    dispatch({
      type: UPDATE_FILTER,
      payload: {
        filtersHotels: sortList,
        sortBy: sortByValue,
      },
    });
  };

  // useEffect(() => {
  //   sortByFn(sortBy);
  // }, [sortBy]);

  const setSortByClick = (sortBy: string) => {
    setSortBy(sortBy);
    sortByFn(sortBy);
  };

  return (
    <section className="sidebar-box sidebar-sort-section">
      <h6>Sort by</h6>
      <div className="">
        <div
          className={`sortItem ${sortBy === "popularity" ? "active" : ""}`}
          onClick={() => setSortByClick("popularity")}
        >
          Popularity
        </div>
        <div
          className={`sortItem ${sortBy === "rating" ? "active" : ""}`}
          onClick={() => setSortByClick("rating")}
        >
          Guest Ratings
        </div>
        <div
          className={`sortItem ${sortBy === "pricelh" ? "active" : ""}`}
          onClick={() => setSortByClick("pricelh")}
        >
          Price Low To High
        </div>
        <div
          className={`sortItem ${sortBy === "pricehl" ? "active" : ""}`}
          onClick={() => setSortByClick("pricehl")}
        >
          Price High To Low
        </div>
      </div>
    </section>
  );
};

export default SortBy;
