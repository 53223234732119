import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";

interface RefundRequestProps {
  roomdata: {
    refund_id: number;
    id: number;
  };
}

interface RefundData {
  status: string;
  title: string;
  msg: string;
  note?: string;
}

const RefundRequest: React.FC<RefundRequestProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [refundData, setRefundData] = useState<RefundData | null>(null);
  const [refundStatus, setRefundStatus] = useState<
    "waiting" | "panding" | "requested" | "error"
  >("waiting");
  const { roomdata } = props;

  useEffect(() => {
    if (roomdata.refund_id === 0) {
      setRefundStatus("panding");
    } else {
      loadData();
    }
  }, [roomdata]);

  const loadData = async () => {
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      formData.append("id", roomdata.refund_id.toString());
      const res = await Api.post(APIURLS.GET_REFUND_REQUEST, formData);
      const responseData = res.data;
      if (responseData.status === "success") {
        setRefundData(responseData.data);
        setRefundStatus("requested");
      } else {
        setRefundData(responseData);
        setRefundStatus("error");
      }
    } catch (error) {
      setRefundStatus("error");
      console.error(error);
    }
  };

  const requestRefund = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      formData.append("booking_id", roomdata.id.toString());
      const res = await Api.post(APIURLS.ADD_REFUND_REQUEST, formData);
      setLoading(false);
      const responseData: any = res.data;
      if (responseData.status === "success") {
        // global.alert(responseData.title, responseData?.msg);
        global.alert(responseData.title);
        setRefundData(responseData.data);
        setRefundStatus("requested");
      } else {
        // global.alert(responseData.title, responseData.msg);
        global.alert(responseData.title);
      }
    } catch (error) {
      console.error("Add Refund Request Error", error);
      setLoading(false);
    }
  };

  if (refundStatus === "waiting") {
    return null;
  }

  if (refundStatus === "panding") {
    return (
      <>
        <button
          onClick={(e) => {
            e.preventDefault();
            requestRefund();
          }}
          type="button"
          style={{ backgroundColor: "#F34D4D" }}
          className="btn btn-danger btn-sm mx-2"
        >
          Request Refund
        </button>
      </>
    );
  }

  if (refundStatus === "error" && refundData) {
    return (
      <span className="text-danger">
        {`${refundData.title}: ${refundData.msg}`}
      </span>
    );
  }

  if (refundStatus === "requested" && refundData) {
    return (
      <div>
        <p style={{ color: "black" }}>
          Refund Status:{" "}
          <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {refundData.status}
          </span>
        </p>
        {refundData.note && refundData.note !== "" && (
          <span style={{ color: "#7f7f7f", fontSize: 12 }}>
            Note:- {refundData.note}
          </span>
        )}
      </div>
    );
  }

  return null;
};

export default RefundRequest;
