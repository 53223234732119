import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { HotelState } from "../../Reducers/HotelView/HotelReducer";
import { CalculatePrice } from "../../helper/PriceCalculator";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import { CONSTANTS } from "../../utils/Constants";
import { PadNumber } from "../../utils/helpers";
import ThanksModal from "./ThanksModal";

interface ConfirmBookingModelProps {
  show: boolean;
  ToggleModal: () => void;
}

const ConfirmBookingModel: React.FC<ConfirmBookingModelProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [bookingSuccessModal, setBookingSuccessModal] = useState(false);
  const hotelContext = useContext(HotelContext);
  const [bookingId, setBookingId] = useState<string | undefined>("");
  const history = useNavigate();

  const RoomContext = hotelContext?.hotelState as HotelState; // Adjust HotelState based on your context definition

  const {
    payablePrice,
    checkOutDate,
    finaldiscount,
    saving,
    checkInDate,
    numberOfNights,
    state,
  } = CalculatePrice(hotelContext?.hotelState as any);

  const ToggleModal = () => {
    setBookingSuccessModal(!bookingSuccessModal);
  };

  const bookRoomPayAtHotel = async (PAYMENT: string) => {
    const formData = await paymentData(PAYMENT);
    setLoading(true);
    Api.post(APIURLS.BOOKROOM, formData)
      .then((res) => {
        setLoading(false);
        let data = res.data;
        if (data.status === "success") {
          setBookingId(data.bookingId);
          props.ToggleModal();
          setBookingSuccessModal(true);
        } else {
          alert(data.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const paymentData = async (PAYMENT: string) => {
    let RandomNumber = Math.floor(Math.random() * 9999999999) + 1;
    let token = await localStorage.getItem("token");

    let formData = new FormData();
    formData.append("token", token || ""); // Ensure token is not null or undefined
    formData.append("room_id", state.room_id as any);
    formData.append("vendor_id", state.vendor_id as any);
    formData.append("hotel_id", state.hotel_id as any);
    formData.append("room_price", payablePrice.toString());
    formData.append("price_drop", saving.toString());
    formData.append("checkin_date", checkInDate);
    formData.append("checkout_date", checkOutDate);
    formData.append("order_id", RandomNumber.toString());
    formData.append("payment", PAYMENT);
    formData.append("guest", state.guest as any);
    formData.append("rooms", state.rooms as any);
    formData.append("coupon_discount", finaldiscount.toString());
    return formData;
  };

  const bookRoomOnline = async (PAYMENT: string) => {
    const formData = await paymentData(PAYMENT);
    setLoading(true);
    Api.post(APIURLS.BOOKROOM, formData)
      .then((res) => {
        setLoading(false);
        let data = res.data;
        if (data.status === "success") {
          payOnline(data.data);
        } else {
          alert(data.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const payOnline = (paymentData: any) => {
    // Adjust type of paymentData based on API response structure
    history("/online-payment", { state: paymentData });
  };

  return (
    <>
      <Modal size="lg" show={props.show} onHide={props.ToggleModal}>
        <Button
          onClick={() => {
            props.ToggleModal();
          }}
          variant={"link"}
          className={`text-danger text-right`}
        >
          <FontAwesomeIcon size={"2x"} icon={faTimesCircle} />
        </Button>
        <div className="page-wrapper modify-booking-page ">
          <section className="">
            <div className="container">
              <div className="">
                <div className="bookingall-details-wrapper">
                  <div className="row">
                    <div className="col-md-4 pr-0">
                      <img
                        alt="img"
                        className="img-fluid w-100"
                        src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${RoomContext.hotel_image}`}
                      />
                    </div>
                    <div className="col-md-8 px-3">
                      <h1 className="hotelvilahouse-name">
                        <img
                          alt="img"
                          src={CONSTANTS.LOGO}
                          style={{ width: 24 }}
                          className={"logoBrandingImage"}
                        />{" "}
                        {PadNumber(RoomContext.hotel_id, 4)}{" "}
                        {RoomContext.hotel_name}
                      </h1>

                      <div className="modify-date">
                        <span>{`${moment(RoomContext.checkInDate).format(
                          "ddd, D MMM  "
                        )} - ${moment(RoomContext.checkOutDate).format(
                          "ddd, D MMM  "
                        )}`}</span>{" "}
                        <span>
                          {RoomContext.rooms} Room, {RoomContext.guest} Guest
                        </span>
                      </div>
                      <div className="modify-room-type">
                        <strong>Selected : </strong>
                        {RoomContext.room_category}
                      </div>
                      <div className="modify-price-details">
                        <ul>
                          <li>
                            <div>
                              Room price for{" "}
                              {moment(RoomContext.checkOutDate).diff(
                                moment(RoomContext.checkInDate),
                                "days"
                              ) + " Nights"}{" "}
                              X {RoomContext.guest} Guest
                            </div>
                            <strong>
                              <i className="fas fa-rupee-sign"></i>{" "}
                              {payablePrice}
                            </strong>
                          </li>
                          <li>
                            <div>Price Drop</div>
                            <strong>
                              <i className="fas fa-rupee-sign"></i> {saving}
                            </strong>
                          </li>
                          <li>
                            <div>
                              Payable Amount<span>(incl. of all taxes)</span>
                            </div>
                            <strong>
                              <i className="fas fa-rupee-sign"></i>{" "}
                              {payablePrice}
                            </strong>
                          </li>
                        </ul>
                      </div>
                      <div className="modify-login-policiy">
                        <Link to="/cancellation-policy">
                          Cancellation Policy
                        </Link>
                        <Link to="/guest-policy">Guest Policies</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bookingall-step2-wrapper ">
                  <h1>Payment Options</h1>
                  <h3 className="text-center my-3">Select Payment Option</h3>
                  <div className="row py-md-3 py-2">
                    <div className="col-md-4">
                      <ul className="nav nav-pills" role="tablist">
                        {(state.partial_payment === "1" ||
                          numberOfNights > 3) && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="pill"
                              href="#pay-partially "
                            >
                              {"Pay Partially"}
                            </a>
                          </li>
                        )}
                        {state.pay_at_hotel === "1" && numberOfNights <= 3 && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="pill"
                              href="#pay-hotel"
                            >
                              {"Pay At Hotel"}
                            </a>
                          </li>
                        )}

                        {state.pay_online === "1" && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="pill"
                              href="#pay-now"
                            >
                              {"Pay Now"}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="col-md-8">
                      <div className="tab-content">
                        <div
                          id="pay-partially"
                          className="payment text-center tab-pane "
                        >
                          <h3>Pay Partially</h3>
                          <p>
                            {"Pay 10% now and rest at hotel"}
                            <br />
                          </p>

                          <Button
                            style={{ maxWidth: "100%", alignItems: "center" }}
                            onClick={() => {
                              bookRoomOnline("partial");
                            }}
                            className="booking-confirm"
                            type="button"
                          >
                            {loading
                              ? "Please wait..."
                              : "Book Now & Pay Online"}
                          </Button>
                        </div>
                        <div
                          id="pay-hotel"
                          className="payment text-center tab-pane"
                        >
                          <h3>No payment needed today</h3>
                          <p>
                            {"We will confirm your stay without any charge."}
                            <br />
                            {"Pay directly at the hotel during your stay."}
                          </p>
                          <Button
                            onClick={() => {
                              bookRoomPayAtHotel("payathotel");
                            }}
                            className="booking-confirm"
                            type="button"
                          >
                            {loading ? "Please wait..." : "Confirm Booking"}
                          </Button>
                        </div>
                        <div
                          id="pay-now"
                          className="payment text-center tab-pane"
                        >
                          <h4>Netbanking, debit card, credit card, UPI</h4>
                          <Button
                            style={{ maxWidth: "100%", alignItems: "center" }}
                            onClick={() => {
                              bookRoomOnline("online");
                            }}
                            className="booking-confirm"
                            type="button"
                          >
                            {loading
                              ? "Please wait..."
                              : "Book Now & Pay Online"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Modal>
      <ThanksModal
        show={bookingSuccessModal}
        bookingId={bookingId}
        ToggleModal={ToggleModal}
      />
    </>
  );
};

export default ConfirmBookingModel;
