import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../../utils/Constants";
import { PadNumber, stringToUrlString } from "../../utils/helpers";

interface Room {
  id: number;
  hotel_name: string;
  master_image: string | null;
  address: string;
  avg_rating: number;
  total_rating: number;
}

interface SingleHotelViewProps {
  room: Room;
}

const SingleHotelView: React.FC<SingleHotelViewProps> = ({ room }) => {
  const roomImage = room.master_image
    ? `${CONSTANTS.IMAGE_BASE_URL}/rooms/${room.master_image}`
    : CONSTANTS.DAFAULT_ROOM_IMAGE;

  return (
    <Link
      style={{ display: "block" }}
      to={`/hotel/${stringToUrlString(room.hotel_name)}/${room.id}/0`}
      className="property-browse-slides"
    >
      <div className="property-browse-imgbox">
        <img className="property-browse-img" src={roomImage} alt="Room" />
      </div>
      <div className="Single-room-block-details">
        <h2>
          <img src={CONSTANTS.LOGO} className="logoBrandingImage" alt="Logo" />
          {PadNumber(room.id, 4)} {room.hotel_name}
        </h2>
        <p>{room.address}</p>
        <div
          className="row ml-0 align-items-center"
          style={{ paddingLeft: 0, marginLeft: 1 }}
        >
          <div className="ratingSmallBox" style={{ width: "18%" }}>
            <p className="mr-2">{Number(room.avg_rating).toFixed(1)}</p>
            <FontAwesomeIcon style={{ fontSize: 13 }} icon={faStar} />
          </div>
          <p
            className="ml-2 "
            style={{ width: "78%" }}
          >{`(${room.total_rating} Ratings) - Excellent`}</p>
        </div>
      </div>
    </Link>
  );
};

export default SingleHotelView;
