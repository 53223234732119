import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import parse from "html-react-parser";
import { APIURLS } from "../../services/APIURLS";
import Api from "../../services/api.service";
import PageLoading from "../../components/page-loading/PageLoading";
// import { RootState } from "../store"; // Adjust the import according to your store setup

interface Settings {
  guest_policy: string;
}

const GuestPolicy: React.FC<PropsFromRedux> = ({ userData }) => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      const formData = new FormData();
      const res = await Api.post(APIURLS.GET_SETTINGS, formData);
      const responseData = res.data;
      setLoading(false);
      if (responseData.status === "success") {
        setSettings(responseData.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to load settings:", error);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="page-wrapper guest-policies-page">
      <div className="container">
        <div className="row wow slideInUp">
          <div className="col-12">
            <h1 className="page-heading">Guest Policies</h1>
          </div>
        </div>
        <div className="row wow slideInUp">
          <div className="col-12">
            <div className="terms-wrapper">
              {settings && parse(settings.guest_policy)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GuestPolicy);
