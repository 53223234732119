import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import PageLoading from "../../components/page-loading/PageLoading";
// import { RootState } from "../redux/store"; // Adjust the import based on your store setup

interface Settings {
  cancellation_policy: string;
}

interface CancellationPolicyProps {
  userData: any; // Adjust the type based on the actual shape of userData
}

const CancellationPolicy: React.FC<CancellationPolicyProps> = ({
  userData,
}) => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      let formData = new FormData();
      let res = await Api.post(APIURLS.GET_SETTINGS, formData);
      let responseData = res.data;
      setLoading(false);
      if (responseData.status === "success") {
        setSettings(responseData.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="page-wrapper guest-policies-page">
      <div className="container">
        <div className="row wow slideInUp">
          <div className="col-12">
            <h1 className="page-heading">Cancellation Policies</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="terms-wrapper">
              {settings ? parse(settings.cancellation_policy) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps)(CancellationPolicy);
