import React, { useEffect, useState } from "react";
import Slider, { Settings } from "react-slick";
import Api from "../../services/api.service";

interface Offer {
  title: string;
  sub_title: string;
  details: string;
  valid_till: string;
}

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 1200,
  slidesToShow: 4,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const BookingsOffers: React.FC = () => {
  const [OffersList, setOffersList] = useState<Offer[]>([]);
  const [DataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    getOffers();
  }, []);

  const getOffers = () => {
    setDataLoading(true);
    const formdata = new FormData();
    Api.post("offers", formdata).then((res) => {
      let data = res.data["data"];
      if (res.data["status"] === "success") {
        setOffersList(data);
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    });
  };

  return (
    <div className="container wow slideInUp">
      <div className="row">
        <div className="col-sm-12 zIndex">
          <div className="offer-slider-wrapper">
            <h2>
              Hotel Booking Offers
              <p>View all live offers for your Hotel bookings</p>
            </h2>
            <div className="offer-slider-wrapper-inner">
              <Slider {...settings}>
                {OffersList.map((item, index) => (
                  <div key={index.toString()} className={`slide${index + 1}`}>
                    <div className="offer-slides">
                      <div className="offer-name">
                        <img
                          className="toptage"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/offer-home.png"
                          }
                          alt="Offer"
                        />
                        <h3>{item.title}</h3>
                        <h4>{item.sub_title}</h4>
                      </div>
                      <div className="offer-details">
                        <p>{item.details}</p>
                        <p>
                          <strong>Valid till :</strong> {item.valid_till}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* <Link to={"#"} className={"offer-explore"}>
              {"All Offer"}
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingsOffers;
