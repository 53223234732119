import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { APIURLS } from "../../services/APIURLS";
import Api from "../../services/api.service";
import PageLoading from "../../components/page-loading/PageLoading";

interface Settings {
  term_and_condition: string;
}

interface ApiResponse {
  status: string;
  data: Settings;
}

interface PrivacyPolicyProps {
  userData: any;
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      let formData = new FormData();
      let res = await Api.post<ApiResponse>(APIURLS.GET_SETTINGS, formData);
      let responseData = res.data;
      setLoading(false);
      if (responseData.status === "success") {
        setSettings(responseData.data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <div className="page-wrapper privacy-policy-page">
        <div className="container">
          <div className="row wow slideInUp">
            <div className="col-12">
              <h1 className="page-heading">Privacy Policy</h1>
            </div>
          </div>
          <div className="row wow slideInUp">
            <div className="col-12">
              <div className="terms-wrapper">
                {settings ? parse(settings.term_and_condition) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps)(PrivacyPolicy);
