import React, { useEffect, useReducer, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import LoginSignupModal from "./Modals/LoginSignupModal";
import SearchBar from "../../../../components/search-bar/SearchBar";
import {
  HOTEL_REDUCER,
  initialStateHotel,
} from "../../../../Reducers/HotelView/HotelReducer";
import { HotelContext } from "../../../../Reducers/HotelView/HotelContext";
import {
  checkAuthenticate,
  logout,
} from "../../../../redux-store/actions/userAction";
import VerifyOtpModal from "../../../modals/VerifyOtpModal";
import UseDevicesResize from "../../../../helper/UseDevicesResize";

interface Props {
  userData: {
    login: boolean;
    full_name: string;
    // Add more properties as needed
  };
  checkLogin: () => void;
  logout: () => void;
}

const SearchTopBar: React.FC<Props> = ({ userData, checkLogin, logout }) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [otpModal, setOtpModal] = useState<boolean>(false);
  const [UserMobile, setUserMobile] = useState<string>("");

  const [dropDown, setDropDown] = useState<boolean>(false);

  const [hotelState, hotelDispatch] = useReducer(
    HOTEL_REDUCER,
    initialStateHotel
  );

  const ToggleModal = () => {
    setModalShow(!modalShow);
  };

  const ToggleVerifyModal = () => {
    setOtpModal(!otpModal);
  };

  useEffect(() => {
    checkLogin();
  }, []);
  const resize = UseDevicesResize();

  return (
    <>
      <HotelContext.Provider
        value={{
          hotelState: hotelState,
          hotelDispatch: hotelDispatch,
        }}
      >
        <header className="search-header wow slideInDown">
          <div className="search-header-wrapper">
            <div className="logo-wrapper">
              <Link to="/">
                <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} />
              </Link>
            </div>
            <div
              style={{
                display: resize.width <= 500 ? "none" : "block",
                width: "100%",
              }}
            >
              <SearchBar />
            </div>

            <div className="profile-wrapper">
              <div className="profile">
                {!userData.login ? (
                  <Button
                    style={{ background: "none", border: "none" }}
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/default-user.png"
                      }
                    />
                    <span className="profile-text">Login / Signup</span>
                  </Button>
                ) : (
                  <div className=" d-inline-block">
                    <button
                      onClick={() => {
                        setDropDown(!dropDown);
                      }}
                      type="button"
                      className="header-profile-btn"
                    >
                      <img
                        className="header-profile-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/default-user-img.png"
                        }
                      />
                      <span className="profile-text">{userData.full_name}</span>
                    </button>
                    {dropDown && (
                      <ul
                        style={{ width: "100%" }}
                        className="profile-dropdown"
                      >
                        <li>
                          <Link
                            onClick={() => setDropDown(false)}
                            to="/profile"
                          >
                            <i className="fas fa-user-circle"></i> Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => setDropDown(false)}
                            to="/profile/booking"
                          >
                            <i className="fas fa-calendar-alt"></i> Booking
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={""}
                            onClick={() => {
                              logout();
                            }}
                          >
                            <i className="fas fa-power-off"></i> Logout
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: resize.width <= 500 ? "block" : "none",
              margin: "4px 6px",
            }}
          >
            <SearchBar />
          </div>
        </header>

        {/* <LoginSignupModal
          show={modalShow}
          ToggleVerifyModal={ToggleVerifyModal}
          ToggleModal={ToggleModal}
        /> */}
        <VerifyOtpModal
          //   mobile={UserMobile}
          show={otpModal}
          ToggleVerifyModal={ToggleVerifyModal}
        />
      </HotelContext.Provider>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkLogin: () => dispatch(checkAuthenticate()),
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTopBar);
