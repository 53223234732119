import React, { useEffect, useState } from "react";
import moment from "moment";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import LoadingContent from "../../utils/LoadingContent";
import BookingRow from "./BookingRow";

interface Booking {
  status: string;
  checkin_date: string;
  [key: string]: any;
}

interface UserBookingProps {}

const UserBooking: React.FC<UserBookingProps> = (props) => {
  const [pageError, setpageError] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(true);
  const [previousBooking, setPreviousBooking] = useState<Booking[]>([]);
  const [upcoming, setUpcoming] = useState<Booking[]>([]);
  const [activeTab, setactiveTab] = useState<number>(1);

  useEffect(() => {
    getBookedRooms();
  }, []);

  const getBookedRooms = async () => {
    const token = await localStorage.getItem("token");
    if (!token) {
      setpageError(true);
      setloading(false);
      return;
    }
    const formData = new FormData();
    formData.append("token", token);
    Api.post(APIURLS.BOOKEDROOMS, formData)
      .then((res) => {
        const data = res.data;
        if (data.status === "success") {
          filterBooking(data.data);
        } else {
          setpageError(true);
        }
      })
      .catch((err) => {
        alert(err);
        setpageError(true);
      });
  };

  const filterBooking = (bookings: Booking[]) => {
    const book: Booking[] = [];
    const coming: Booking[] = [];
    const todayDate = moment().utcOffset("+05:30").format("YYYY-MM-DD");
    bookings.forEach((booking) => {
      if (booking.status === "booked" && todayDate <= booking.checkin_date) {
        coming.push(booking);
      } else {
        book.push(booking);
      }
    });
    setPreviousBooking(book);
    setUpcoming(coming);
    setloading(false);
    setpageError(false);
  };

  const bookedRooms = activeTab === 1 ? upcoming : previousBooking;

  return (
    <div id="menu1" className={`tab-pane`}>
      <div className="tabs-heading">
        <span
          onClick={() => setactiveTab(1)}
          className={`btn py-0 px-1 ${
            activeTab === 1 ? "font-weight-bold" : "text-muted"
          }`}
        >
          {"Upcoming Booking"}
        </span>
        <span
          onClick={() => setactiveTab(2)}
          className={`btn py-0 px-1 ${
            activeTab === 2 ? "font-weight-bold" : "text-muted"
          }`}
        >
          {"Completed Booking"}
        </span>
      </div>

      <div className="booking-view-wrapper">
        {loading ? (
          <LoadingContent />
        ) : (
          <>
            {bookedRooms.length === 0 ? (
              <div className={"py-5 text-center"}>
                <h4>{"No Booking"}</h4>
              </div>
            ) : (
              <>
                {bookedRooms.map((list, index) => (
                  <BookingRow
                    key={index.toString()}
                    roomsdata={list as any}
                    refreshData={getBookedRooms}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserBooking;
