import Api from "./api.service";

interface Action {
  url: string;
  values: FormData | Record<string, any>;
  onSuccessCallback?: (data: any) => void;
  loading?: (isLoading: boolean) => void;
  onError?: (error: any) => void;
  formData?: boolean;
  showloading?: boolean;
}

const ApiRequest = async (action: Action): Promise<void> => {
  const {
    url,
    values,
    onSuccessCallback,
    loading,
    onError,
    formData = false,
    showloading = true,
  } = action;

  showloading && loading && loading(true);

  try {
    let response;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (formData) {
      response = await Api.post(url, values, config);
    } else {
      response = await Api.post(url, values);
    }

    const data = response.data;
    if (data.status === "success") {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    } else {
      onError ? onError(data) : alert(data.msg);
    }
  } catch (error: any) {
    onError ? onError(error.message) : alert(error.message);
  } finally {
    loading && loading(false);
  }
};

export default ApiRequest;
