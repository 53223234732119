import React, { createContext } from "react";

type FilterContextType = {
  // Define the shape of your context here. For example:
  // filters: string[];
  // setFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

export const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);
