import React, { useContext, useEffect } from "react";
import { ApplyFilter } from "../../helper/searchHelper";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import NoDataPage from "../NoDataPage";
import HorizontalRoomViewCategory from "../category-rooms/HorizontalRoomViewCategory";

interface Hotel {
  // Define the properties of a hotel object here
  id: number;
  hotel_name: string;
  address: string;
  couple_friendly: number;
  avg_rating: number;
  total_rating: number;
  amenities: any[]; // Define a more specific type if available
  room_images: string[];
  hotel_images: string[];
  rooms: string; // Assuming this is a JSON string; you might want to parse it into a more specific type
}

interface FilterState {
  priceValues: any; // Replace `any` with the actual type for priceValues
  guestRating: any; // Replace `any` with the actual type for guestRating
  selectedAmenities: any; // Replace `any` with the actual type for selectedAmenities
  selectedCategories: any; // Replace `any` with the actual type for selectedCategories
  originalHotelsList: Hotel[];
  filtersHotels: Hotel[];
}

type FilterAction = any; // Replace `any` with the actual type for FilterAction if available

interface FilterContextType {
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
}

const FiltersResult: React.FC = () => {
  const { state, dispatch } = React.useContext(
    FilterContext
  ) as FilterContextType;

  useEffect(() => {
    ApplyFilter(
      {
        priceValues: state.priceValues,
        guestRating: state.guestRating,
        selectedAmenities: state.selectedAmenities,
        selectedCategories: state.selectedCategories,
        originalHotelsList: state.originalHotelsList,
      },
      dispatch
    );
  }, [
    state.priceValues,
    state.guestRating,
    state.selectedAmenities,
    state.selectedCategories,
    state.originalHotelsList,
    dispatch,
  ]);

  if (state.originalHotelsList.length === 0) {
    return <NoDataPage title="No Rooms In Category" />;
  }

  if (state.filtersHotels.length === 0) {
    return <NoDataPage title="No Rooms -- Modify Filter" />;
  }

  return (
    <div className="right-property-list-wrapper">
      <div className="container">
        {state.filtersHotels.map((hotel, index) => (
          <HorizontalRoomViewCategory
            key={index.toString()}
            hotelData={hotel}
          />
        ))}
      </div>
    </div>
  );
};

export default FiltersResult;
