import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Api from "../../services/api.service";
import SingleHotelView from "./SingleHotelView";

interface Hotel {
  // Define the structure of a hotel object based on the API response
  id: number;
  hotel_name: string;
  master_image: string | null;
  address: string;
  avg_rating: number;
  total_rating: number;
}

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SilverStays: React.FC = () => {
  const [similarHotels, setSimilarHotels] = useState<Hotel[]>([]);

  useEffect(() => {
    getHotels();
  }, []);

  const getHotels = async () => {
    setSimilarHotels([]);
    const formData = new FormData();
    try {
      const res = await Api.post("hotel/similar_hotels", formData);
      const data = res.data;
      if (data.status === "success") {
        setSimilarHotels(data.data);
      } else {
        setSimilarHotels([]);
      }
    } catch (err) {
      // Handle error if needed
      console.error(err);
    }
  };

  return (
    <div className="container wow slideInUp pb-4">
      <div className="row">
        <div className="col-sm-12">
          <div className="property-browse-wrapper">
            <h1 className="title1">Silver Stays</h1>
            <Slider {...settings} className="property-browse-wrapper-inner">
              {similarHotels.map((room, index) => (
                <SingleHotelView room={room} key={index.toString()} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SilverStays;
