import { createBrowserRouter } from "react-router-dom";
import Layout from "../container/layout/Layout";
import AboutUs from "../pages/about-us/AboutUs";
import Support from "../pages/support/Support";
import ContactUs from "../pages/contact-us/ContactUs";
import CancellationPolicy from "../pages/cancellation-policy/CancellationPolicy";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import GuestPolicy from "../pages/guest-policy/GuestPolicy";
import Home from "../pages/home/HomePage";
import ListYourProperty from "../pages/list-your-property/ListYourProperty";
import Profile from "../pages/profile/Profile";
import HotelView from "../pages/hotel-view/HotelView";
import Search from "../pages/search/Search";
import HotelByCategories from "../pages/hotel-by-categories/HotelByCategories";
import HotelByCities from "../pages/hotel-by-cities/HotelByCities";
import TermAndCondition from "../pages/term-and-condition/TermAndCondition";
import PageNotFound from "../pages/page-not-found/PageNotFound";
import ModifyBooking from "../pages/modify-booking/ModifyBooking";
import OnlinePaymentPage from "../pages/online-payment-page/OnlinePaymentPage";
import PaymentStatusPage from "../pages/payment-status-page/PaymentStatusPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/support", element: <Support /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/cancellation-policy", element: <CancellationPolicy /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/guest-policy", element: <GuestPolicy /> },
      { path: "/list-your-property", element: <ListYourProperty /> },
      { path: "/profile", element: <Profile /> },
      { path: "/profile/:tab", element: <Profile /> },
      { path: "/hotel/:title/:id/:roomId", element: <HotelView /> },
      { path: "/hotel-by-category/:id", element: <HotelByCategories /> },
      { path: "/hotel-by-city/:id", element: <HotelByCities /> },
      { path: "/search", element: <Search /> },
      { path: "/search/:filter", element: <Search /> },
      { path: "/term-and-condition", element: <TermAndCondition /> },
      { path: "/book-hotel", element: <ModifyBooking /> },
      { path: "/online-payment", element: <OnlinePaymentPage /> },
      { path: "/booking-status/:booking_id", element: <PaymentStatusPage /> },
      { element: <PageNotFound /> },
    ],
  },
]);
