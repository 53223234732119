import React, { useReducer } from "react";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import {
  HOTEL_REDUCER,
  initialStateHotel,
} from "../../Reducers/HotelView/HotelReducer";
import HomeSearchingBlock from "../../components/home/HomeSerchingBlock";
import BookingsOffers from "../../components/home/BookingsOffers";
import HotelByCategory from "../../components/home/HotelByCategory";
import SilverStays from "../../components/home/SilverStays";

const Home: React.FC = () => {
  const [hotelState, hotelDispatch] = useReducer(
    HOTEL_REDUCER,
    initialStateHotel
  );

  return (
    <>
      <HotelContext.Provider
        value={{
          hotelState: hotelState,
          hotelDispatch: hotelDispatch,
        }}
      >
        <HomeSearchingBlock />
        <BookingsOffers />
        <HotelByCategory />
        <SilverStays />
      </HotelContext.Provider>
    </>
  );
};

export default Home;
