export const APIURLS = {
  USERLOGIN: "user_login",
  ADD_MOBILE: "user_login/add_mobile",
  LOGIN_WITH_GOOGLE: "user_login/login_with_gmail",
  USERSIGNUP: "user_signup",
  VERIFYUSER: "otp/verify_user",
  FORGOTUSER: "forgot_password",
  RESETPASSUSER: "reset_password",
  GETPROFILE: "user/get_profile",
  UPDATEPROFILE: "user/update_profile",
  LISTPROPERTY: "list_your_property",
  GETHOMECITIES: "/home/get_home_city",
  GETCITYHOTELS: "hotel/city_hotels",
  GETHOTELOFFERS: "offers",
  GETHOTELDETAILS: "hotel/get_hotel_details",
  IMAGEURL: `${process.env.REACT_APP_BASE_URL}`,
  IMAGE_URL_USER_PROFILE: `${process.env.REACT_APP_BASE_URL}/user_profile_image/`,
  SAVEHOTEL: "savehotel/save_hotel",
  SIMILARHOTELS: "hotel/similar_hotels",
  SAVEDHOTEL: "savehotel/get_saved_hotels",
  REMOVESAVEDHOTEL: "savehotel/delete_save_hotel",
  GETALLAMENITIES: "amenities",
  BOOKROOM: "Booked_room/book_room",
  PAYMENT_STATUS: "Booked_room/get_payment_status",
  BOOKEDROOMS: "Booked_room/get_booked_rooms",
  CANCELBOOKING: "Booked_room/cancel_booking",
  APPLYCOUPON: "coupon/apply_coupon",
  HELP: "Need_help",
  HOMEROOMCATEGORIES: "home/browse_by_room_cate",
  GETCATEGORIESROOM: "hotel/get_rooms_by_category",
  GETPOLICYRULES: "policy",
  GETALLCOUPONS: "coupon",
  GIVERATING: "/rating/give_rating",
  CONTACTUS: "user/add_contact_request",
  GETHOTELREVIEWSRATING: "/hotelRating/get_hotel_review",
  SUBMIT_PROPERTY_DETAILS: "list_your_property/submit_property_details",

  // VENDOR URLS
  VEDORLOGIN: "Vendor/vendor_login",
  FORGOTVENDOR: "Vendor/forgot_password",
  VERIFYVENDOR: "Vendor/otp/verify_vendor",
  RESETPASSVendor: "Vendor/reset_password",
  VENDORBOOKEDROOMS: "Vendor/Vendor_booked_rooms",
  VENDORROOMS: "Vendor/Vendor_booked_rooms/get_vendor_rooms",
  VENDORHOTELS: "Vendor/Vendor_booked_rooms/get_vendor_hotels",
  WALKIN: "Vendor/Vendor_booked_rooms/vendor_room_book",
  GETVENDORPROFILE: "Vendor/vendor/get_profile",
  GETVENDORHOTEL: "Vendor/hotels",
  CHECK_IN_ROOM: "Vendor/Vendor_booked_rooms/vendor_checkin",
  CHECKED_IN_ROOMS: "Vendor/Vendor_booked_rooms/checked_in_rooms",
  YESTERDAY_CHECKED_IN_ROOMS:
    "Vendor/Vendor_booked_rooms/yesterday_checked_in_rooms",
  GET_OCCOUPANCY: "Vendor/Vendor_booked_rooms/occupancy",
  CHECKED_OUT_ROOM: "Vendor/Vendor_booked_rooms/vendor_checkout",
  GET_HOTEL_ROOMS: "Vendor/Vendor_booked_rooms/get_hotel_rooms",
  COMPLETED_BOOKING: "Vendor/Vendor_booked_rooms/completed_bookings",
  RAISEISSUE: "raise_issues/add_request",
  SEARCH_NEAR_BY: "hotelSearch/search_near_by",
  SEARCH_HOTEL: "hotelSearch/search_hotels",
  ALL_ROOM_CATEGORY: "home/all_room_category",
  GET_SETTINGS: "settings",
  ADD_REFUND_REQUEST: "refund",
  GET_REFUND_REQUEST: "refund/get",
};
