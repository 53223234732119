import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { UPDATE_ROOM_DATA } from "../../Reducers/HotelView/HotelReducer";

interface Props {}

const RoomGuestSelectorModal = forwardRef(
  (props: Props, ref: React.Ref<any>) => {
    const hotelContext = React.useContext(HotelContext);
    const RoomContext = hotelContext?.hotelState;

    const [numofguest, setnumofguest] = useState<number>(
      RoomContext?.rooms ?? 0
    );
    const [numofroom, setnumofroom] = useState<number>(RoomContext?.guest ?? 0);
    const [showmodel, setshowmodel] = useState<boolean>(false);
    const location = useLocation();

    useImperativeHandle(
      ref,
      () => ({
        numofroom: numofroom,
        numofguest: numofguest,
        roomGuestUpdate: saveRoomGuest,
      }),
      [numofroom, numofguest]
    );

    const saveRoomGuest = (room: number, guest: number) => {
      setshowmodel(false);
      setnumofroom(room);
      setnumofguest(guest);
      let payload = {
        rooms: room,
        guest: guest,
        offerfinalamount: 0,
        discountedAmount: 0,
      };

      console.log("payload", payload);
      console.log("RoomContext", RoomContext);

      hotelContext?.hotelDispatch({
        type: UPDATE_ROOM_DATA,
        payload: payload,
      });
    };

    useEffect(() => {
      setTimeout(() => {
        setQueryRoomGuest();
      }, 100);
    }, []);

    const setQueryRoomGuest = () => {
      const params = new URLSearchParams(location.search);
      let TOTAL_ROOM = numofroom;
      let TOTAL_GUEST = numofguest;
      if (params.get("rooms")) {
        TOTAL_ROOM = Number(params.get("rooms"));
      }
      if (params.get("guests")) {
        TOTAL_GUEST = Number(params.get("guests"));
      }
      const filteredRoom = validateRoomAndGuest(TOTAL_ROOM, TOTAL_GUEST);
      saveRoomGuest(filteredRoom.rooms, filteredRoom.guests);
    };

    const validateRoomAndGuest = (room: number, guests: number) => {
      if (room > 6) {
        room = 6;
      }
      if (room < 0) {
        room = 0;
      }

      if (room <= 6) {
        if (guests < room) {
          guests = room;
        } else if (guests > room * 3) {
          guests = room * 3;
        }
      }

      if (room > 0) {
        if (room * 3 < guests) {
          guests = room * 3;
        }
      }

      if (guests > room * 3) {
        guests = room * 3;
      }

      if (guests < room) {
        guests = room;
      }

      return {
        rooms: room,
        guests: guests,
      };
    };

    const roonIncrease = () => {
      if (numofroom <= 5) {
        let room = numofroom + 1;
        if (numofguest < room) {
          setnumofroom(room);
          setnumofguest(room);
        } else if (numofguest > room * 3) {
          setnumofguest(room * 3);
          setnumofroom(room);
        } else {
          setnumofroom(room);
        }
      }
    };

    const roonDecrease = () => {
      if (numofroom > 1) {
        let room = numofroom - 1;
        if (room * 3 < numofguest) {
          setnumofroom(room);
          setnumofguest(room * 3);
        } else {
          setnumofroom(room);
        }
      }
    };

    const increaseguest = () => {
      if (numofguest > numofroom * 3) {
        setnumofguest(numofroom * 3);
      }

      if (numofguest < numofroom * 3) {
        setnumofguest(numofguest + 1);
      }
    };

    const decreaseGuest = () => {
      if (numofguest > numofroom * 3) {
        setnumofguest(numofroom * 3);
      }

      if (numofguest > numofroom) {
        setnumofguest(numofguest - 1);
      }
    };

    return (
      <>
        <div className="panel-dropdown p-0">
          <div
            className={`panel-dropdown-content ${
              showmodel ? "Active-Picker-Dropdown" : ""
            }`}
          >
            <div className="qtyButtons">
              <label>Room</label>
              <div onClick={roonDecrease} className="qtyDec"></div>
              <input type="text" name="qtyRInput" value={numofroom} readOnly />
              <div onClick={roonIncrease} className="qtyInc"></div>
            </div>

            <div className="qtyButtons">
              <label>Adults</label>
              <div onClick={decreaseGuest} className="qtyDec"></div>
              <input type="text" name="qtyRInput" value={numofguest} readOnly />
              <div onClick={increaseguest} className="qtyInc"></div>
            </div>

            <div
              onClick={() => {
                saveRoomGuest(numofroom, numofguest);
              }}
              className="submit-btn-save"
            >
              <p>Save</p>
            </div>
          </div>
        </div>
        <p
          style={{ padding: 20 }}
          onClick={() => setshowmodel(!showmodel)}
          className="DateGuestText  borderLeftDate"
        >
          {RoomContext?.rooms + " Room, " + RoomContext?.guest + " Guest"}
        </p>
      </>
    );
  }
);

export default RoomGuestSelectorModal;
